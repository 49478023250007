<div class="col-12 booking-details">
  <div class="col-12 panel-div booking-details-header" style="box-shadow: 1px 1px 1px #aaaaaa;">
    <div class="col-10 md:col-9 lg:col-9">
      <div class="trip-id">
        <span translate>label_tripID</span>
        <span> : {{tourBookingInfo !== null ? tourBookingInfo.tripId : ''}}</span>
      </div>
      <div class="trip-time">
        <span>{{(tourBookingInfo !== null ? tourBookingInfo.createdAt : '')| datetimeformat}}</span>
        <span style="margin-left: 5px;">|</span>
        <i class="fa fa-car"></i>
        <span> {{vehicleInfo !== null ? vehicleInfo.vehicleType : ''}}</span>
      </div>
      <div *ngIf="tourBookingInfo !== null" class="trip-status">
        <span class="key" translate>label_booking_type</span>
        <span class="key"> : </span>
        <span class="key" *ngIf="tourBookingInfo.rideLater" translate>label_ride_later</span>
        <span class="key" *ngIf="!tourBookingInfo.rideLater" translate>label_ride_now</span>
      </div>
      <div *ngIf="tourBookingInfo !== null && tourBookingInfo.rideLater" class="trip-status">
        <span class="key" translate>label_ride_schedule_at</span>
        <span class="key"> : </span>
        <span class="key">{{(tourBookingInfo.rideLaterPickupTime !== null ? tourBookingInfo.rideLaterPickupTime : '')|
          datetimeformat}}</span>
      </div>
      <div class="trip-status">
        <span class="key" translate>label_booking_status</span>
        <span class="key"> : </span>
        <span class="value">{{tourBookingInfo !== null ? tourBookingInfo.tourStatusString : ''}}</span>
        <span class="value" *ngIf="tourBookingInfo?.tourStatusString == 'Cancelled'"> by
          {{tourBookingInfo.cancelledByUser | lowercase}}</span>
      </div>
      <div
        *ngIf="tourBookingInfo !== null && tourBookingInfo.cancellationReason !== '' && tourBookingInfo.cancellationReason !== null"
        class="trip-status">
        <span class="key" translate>label_cancellation_reason</span>
        <span class="key"> : </span>
        <span class="key">{{tourBookingInfo.cancellationReason}}</span>
      </div>
      <div class="trip-status" *ngIf="tripNote && tourBookingInfo.tripNote">
        <span class="key" translate>label_trip_note</span>
        <span class="key"> : </span>
        <span class="key"> {{tourBookingInfo.tripNote}}</span>
      </div>
    </div>
    <div class="col-3 md:col-3 lg:col-3 amount"> {{estimatesInfo !== null ? estimatesInfo.grandTotalString : ''}}</div>
  </div>

  <div class="col-12 md:col-12 lg:col-12 panel-div address-container"
    style="margin-top: 10px;box-shadow: 1px 1px 1px #aaaaaa;">
    <div class="col-6 md:col-6 lg:col-6">
      <div class="col-12 md:col-12 lg:col-12" style="height: 125px;">
        <div class="col-1 md:col-1 lg:col-1" style="padding-top: 34px;">
          <div class="start-point"></div>
          <div class="divider"></div>
        </div>
        <div class="col-11 md:col-11 lg:col-11">
          <p class="time">{{tourBookingInfo.tourStatus == 140 ? (tourBookingInfo.pickupTime | minutsformat) : ' '}}</p>
          <div>{{tourBookingInfo !== null ? tourBookingInfo.pickupLocationName : ''}}</div>
        </div>
      </div>

      <div class="col-12 md:col-12 lg:col-12" style="height: 125px;">
        <div class="col-1 md:col-1 lg:col-1" style="padding-top: 34px;">
          <div class="end-point"></div>
        </div>
        <div class="col-11 md:col-11 lg:col-11">
          <p class="time">{{tourBookingInfo.tourStatus == 140 ? (tourBookingInfo.dropTime | minutsformat) : ' '}}</p>
          <div>{{tourBookingInfo !== null ? tourBookingInfo.dropLocationName : ''}}</div>
        </div>
      </div>
    </div>

    <div class="col-6 md:col-12 lg:col-6" *ngIf="routeImageUrl === null">
      <div id="tripMap" style="width: 100%;height: 250px;"></div>
    </div>

    <div *ngIf="routeImageUrl !== null">
      <div class="col-2 md:col-12 lg:col-2">
      </div>
      <div class="col-4 md:col-12 lg:col-4">
        <img style="width: 250px;height: 250px;" src="{{routeImageUrl}}">
      </div>
    </div>
  </div>

  <div class="col-12 md:col-12 lg:col-12 panel-div booking-detail-container"
    style="margin-top: 10px;box-shadow: 1px 1px 1px #aaaaaa;">
    <div class="col-6 md:col-12 lg:col-6">
      <div class="col-12 md:col-12 lg:col-12 header">
        <span translate>label_rider_details</span>
      </div>
      <div class="col-12 md:col-12 lg:col-12">
        <table>
          <tbody>
            <tr>
              <td class="key" translate>label_name</td>
              <td class="container-height w-10-percent">-</td>
              <td>{{passengerInfo !== null ? passengerInfo.firstName: ''}} {{passengerInfo !== null ?
                passengerInfo.lastName:
                ''}}
              </td>
            </tr>
            <tr>
              <td class="key" translate>label_phone_number</td>
              <td class="container-height w-10-percent">-</td>
              <td>{{passengerInfo !== null ? passengerInfo.numCountryCode : ''}} - {{passengerInfo !== null ?
                passengerInfo.phoneNum
                : ''}}</td>
            </tr>
            <tr>
              <td class="key" translate>label_email</td>
              <td class="container-height w-10-percent">-</td>
              <td>{{passengerInfo !== null ? passengerInfo.email : ''}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-12 md:col-12 lg:col-12 rating">
        <div class="col-12 md:col-12 lg:col-12 rating-header">
          <span translate>label_rating_given_by_driver</span>
        </div>

        <div class="col-12 md:col-12 lg:col-12 rating-container">
          <div class="rating-stars">
            <span *ngFor="let star of maxRating;let idx = index">
              <i *ngIf="(idx + 1) <= (tourBookingInfo === null ? 0 : tourBookingInfo.driverRating)"
                class="fa fa-star active"></i>
              <i *ngIf="(idx + 1) > (tourBookingInfo === null ? 0 : tourBookingInfo.driverRating)"
                class="fa fa-star"></i>
            </span>
          </div>

          <div class="rating-comments">
            {{tourBookingInfo !== null ? tourBookingInfo.driverComment : ''}}
          </div>
        </div>
      </div>
    </div>

    <div class="col-6 md:col-12 lg:col-6">
      <div class="col-12 md:col-12 lg:col-12 header">
        <span translate>label_driver_details_header</span>
      </div>
      <div class="col-12 md:col-12 lg:col-12">
        <table>
          <tbody>
            <tr>
              <td class="key" translate>label_name</td>
              <td class="container-height w-10-percent">-</td>
              <td>{{driverInfo !== null ? driverInfo.firstName: ''}} {{driverInfo !== null ? driverInfo.lastName: ''}}
              </td>
            </tr>
            <tr>
              <td class="key" translate>label_phone_number</td>
              <td class="container-height w-10-percent">-</td>
              <td>{{driverInfo !== null ? driverInfo.numCountryCode: ''}} - {{driverInfo !== null ?
                driverInfo.phoneNum: ''}}
              </td>
            </tr>
            <tr>
              <td class="key" translate>label_email</td>
              <td class="container-height w-10-percent">-</td>
              <td>{{driverInfo !== null ? driverInfo.email: ''}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-12 md:col-12 lg:col-12 rating">
        <div class="col-12 md:col-12 lg:col-12 rating-header">
          <span translate>label_rating_given_by_passenger</span>
        </div>

        <div class="col-12 md:col-12 lg:col-12 rating-container">
          <div class="rating-stars">
            <span *ngFor="let star of maxRating;let idx = index">
              <i *ngIf="(idx + 1) <= (tourBookingInfo === null ? 0 : tourBookingInfo.passengerRating)"
                class="fa fa-star active"></i>
              <i *ngIf="(idx + 1) > (tourBookingInfo === null ? 0 : tourBookingInfo.passengerRating)"
                class="fa fa-star"></i>
            </span>
          </div>

          <div class="rating-comments">
            {{tourBookingInfo !== null ? tourBookingInfo.passengerComment : ''}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 md:col-12 lg:col-12 panel-div booking-detail-container"
    style="margin-top: 10px;box-shadow: 1px 1px 1px #aaaaaa;">

    <div class="col-4 md:col-12 lg:col-4" *ngIf="fareBreakDown">
      <div class="col-12 md:col-12 lg:col-12 header">
        <span translate>label_fare_details</span>
      </div>
      <div class="col-12 md:col-12 lg:col-12">
        <table>
          <tbody>
            <tr *ngFor="let fare of fareBreakDown;">
              <td *ngIf="fare.highlight === true" class="statistic-key-highlight">{{fare.key}}</td>
              <td *ngIf="fare.highlight === false" class="statistic-key">{{fare.key}}</td>
              <td class="container-height w-10-percent">-</td>
              <td *ngIf="fare.highlight === true" class="statistic-key-highlight">{{fare.value}}</td>
              <td *ngIf="fare.highlight === false">{{fare.value}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="col-4 md:col-12 lg:col-4" *ngIf="tripStatistics">
      <div class="col-12 md:col-12 lg:col-12 header">
        <span translate>label_trip_statistics</span>
      </div>
      <div class="col-12 md:col-12 lg:col-12">
        <table>
          <tbody>
            <tr *ngFor="let statistic of tripStatistics;">
              <td *ngIf="statistic.highlight === true" class="statistic-key-highlight">{{statistic.key}}</td>
              <td *ngIf="statistic.highlight === false" class="statistic-key">{{statistic.key}}</td>
              <td class="container-height w-10-percent">-</td>
              <td *ngIf="statistic.highlight === true" class="statistic-key-highlight">{{statistic.value}}</td>
              <td *ngIf="statistic.highlight === false">{{statistic.value}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="col-4 md:col-12 lg:col-4" *ngIf="tripDetailsInfo">
      <div class="col-12 md:col-12 lg:col-12 header">
        <span translate>label_trip_details</span>
      </div>
      <div class="col-12 md:col-12 lg:col-12">
        <table>
          <tbody>
            <tr *ngFor="let detail of tripDetailsInfo;">
              <td *ngIf="detail.highlight === true" class="statistic-key-highlight">{{detail.key}}</td>
              <td *ngIf="detail.highlight === false" class="statistic-key">{{detail.key}}</td>
              <td class="container-height w-10-percent">-</td>
              <td *ngIf="detail.highlight === true" class="statistic-key-highlight">{{detail.value}}</td>
              <td *ngIf="detail.highlight === false">{{detail.value}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>

  <div *ngIf="biddings.length > 0" class="col-12 md:col-12 lg:col-12 panel-div booking-detail-container"
    style="margin-top: 10px;box-shadow: 1px 1px 1px #AAA;">
    <div class="col-12 md:col-12 lg:col-12 header" style="margin-bottom:15px;">
      <span translate>label_bidding_details</span>
    </div>
    <table class="bidding-detail-container">
      <tr>
        <th translate>label_driver_name</th>
        <th translate>label_company</th>
        <th translate>label_bid_amount</th>
        <th translate>label_date_and_time</th>
        <th translate>label_driver_status</th>
        <th translate>label_passenger_status</th>
      </tr>

      <tbody *ngFor="let bid of biddings; let idx=index">
        <tr *ngIf="idx % 2 !== 0" class="odd">
          <td>{{bid.driverName}}</td>
          <td>{{bid.companyName}}</td>
          <td>{{bid.bidAmountStr}}</td>
          <td>{{bid.requestAssignedAt | datetimeformat}}</td>
          <td style="color:green;font-weight: bold;" *ngIf="bid.driverStatusStr === 'Accepted'">{{bid.driverStatusStr}}
          </td>
          <td style="color:red;font-weight: bold;" *ngIf="bid.driverStatusStr !== 'Accepted'">{{bid.driverStatusStr}}
          </td>
          <td style="color:green;font-weight: bold;" *ngIf="bid.passengerStatus === 'Accepted'">{{bid.passengerStatus}}
          </td>
          <td style="color:red;font-weight: bold;" *ngIf="bid.passengerStatus !== 'Accepted'">{{bid.passengerStatus}}
          </td>
        </tr>
        <tr *ngIf="idx % 2 === 0" class="even">
          <td>{{bid.driverName}}</td>
          <td>{{bid.companyName}}</td>
          <td>{{bid.bidAmountStr}}</td>
          <td>{{bid.requestAssignedAt | datetimeformat}}</td>
          <td style="color:green;font-weight: bold;" *ngIf="bid.driverStatusStr === 'Accepted'">{{bid.driverStatusStr}}
          </td>
          <td style="color:red;font-weight: bold;" *ngIf="bid.driverStatusStr !== 'Accepted'">{{bid.driverStatusStr}}
          </td>
          <td style="color:green;font-weight: bold;" *ngIf="bid.passengerStatus === 'Accepted'">{{bid.passengerStatus}}
          </td>
          <td style="color:red;font-weight: bold;" *ngIf="bid.passengerStatus !== 'Accepted'">{{bid.passengerStatus}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>

<p-toast></p-toast>