import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Message, MessageService } from 'primeng/api';
import { ErrorBindingService } from './../../services/error-binding.service';
import { CommonBindingDataService } from './../../services/common-binding-data.service';
import { UserCommonService } from './../../services/user-common.service';
import { AppSettings } from '../../app.settings';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  providers: [MessageService]
})

export class ChangePasswordComponent implements OnInit {
  @Input() changePasswordPopup: boolean;
  @Output() isShowChangePassword: EventEmitter<any> = new EventEmitter();

  errorMessage: Message[] = [];
  change_password;
  old_password;
  new_password;
  confirm_password;

  changePasswordErrors = [
    {
      oldPassword: false,
      newPassword: false,
      newConfirmPassword: false,
    }
  ];

  chagePasswordForm = new UntypedFormGroup({
    oldPassword: new UntypedFormControl(),
    newPassword: new UntypedFormControl(),
    newConfirmPassword: new UntypedFormControl()
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private signOutService: UserCommonService,
    private commonService: CommonBindingDataService,
    private errorBindingService: ErrorBindingService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.chagePasswordForm = this.formBuilder.group({
      oldPassword: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(25)
      ]],
      newPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(25),
        Validators.pattern(AppSettings.PASSWORD_PATTERN)
      ]],
      newConfirmPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(25),
        Validators.pattern(AppSettings.PASSWORD_PATTERN)
      ]]
    });

    this.old_password = this.commonService.getLabel('label_old_password');
    this.new_password = this.commonService.getLabel('label_new_password');
    this.confirm_password = this.commonService.getLabel('label_new_confirm_password');
    this.change_password = this.commonService.getLabel('label_change_password');
  }

  passwordNotMatched() {
    if (this.chagePasswordForm.controls.newPassword.value !== this.chagePasswordForm.controls.newConfirmPassword.value) {
      const error = {
        general: [
          {
            message: this.commonService.getLabel('error_new_confirm_password_not_match')
          }
        ]
      };
      this.errorBindingService.onAPIValidationError(error, this.changePasswordErrors);
    } else {
      return true;
    }
  }

  requestData() {
    return {
      oldPassword: this.chagePasswordForm.controls.oldPassword.value,
      newPassword: this.chagePasswordForm.controls.newPassword.value,
    };
  }

  doChangePassword() {
    const passwordVerified = this.passwordNotMatched();
    if (passwordVerified) {
      const data = this.requestData();
      const c = this;
      this.signOutService.passwordChange(JSON.stringify(data)).subscribe(results => {
        if (results.general !== null && results.general !== undefined) {
          this.showMessage('Success', results.general[0].message);
          setTimeout(() => {
            this.chagePasswordForm.reset();
            this.isShowChangePassword.emit(false);
            this.errorMessage = [];
          }, 2000);
        }
      }, (error) => {
        this.showMessage('Error', error.general[0].message);
      });
    }
  }

  onCancel() {
    this.chagePasswordForm.reset();
    this.isShowChangePassword.emit(false);
  }

  showMessage(severity, message) {
    this.errorMessage = [];
    this.messageService.add({ severity: severity.toLowerCase(), summary: severity, detail: message });
  }

}
