<div class="grid form-container">
  <form [formGroup]="chauffeurPricingForm">

    <div class="grid">
      <div class="form-field col-12 md:col-6 lg:col-6">
        <div class="form-label grid">
          <label for="packageName" translate>label_package_name</label>
          <label class="asterisk">*</label>
        </div>
        <div class="grid">
          <input autocomplete="off" type="text" pInputText [appDisableControl]="corporatePricing"
            formControlName="packageName" (focus)="settingApiError[0]['packageName']=false" />
        </div>
        <div class="form-errors alert alert-danger" [hidden]='!settingApiError[0]["packageName"]'>
          <div *ngFor="let ErrorMessage of settingApiError['packageName']">
            {{ErrorMessage.message}}
          </div>
        </div>
        <div class="form-errors"
          *ngIf="chauffeurPricingForm.controls.packageName.errors  && (chauffeurPricingForm.controls.packageName.dirty || chauffeurPricingForm.controls.packageName.touched)">
          <div [hidden]="!chauffeurPricingForm.controls.packageName.errors.required" translate>
            package
          </div>
          <div [hidden]="!chauffeurPricingForm.controls.packageName.errors.pattern" translate>
            invalid_package
          </div>
        </div>
      </div>

      <div class="form-field col-12 md:col-6 lg:col-6">
        <div class="form-label grid">
          <label for="hours" translate>label_hours</label>
          <label class="asterisk">*</label>
        </div>
        <div class="grid">
          <input autocomplete="off" type="text" pInputText [appDisableControl]="corporatePricing"
            formControlName="hours" (focus)="settingApiError[0]['minutes']=false" />
        </div>
        <div class="form-errors alert alert-danger" [hidden]='!settingApiError[0]["minutes"]'>
          <div *ngFor="let ErrorMessage of settingApiError['minutes']">
            {{ErrorMessage.message}}
          </div>
        </div>
        <div class="form-errors"
          *ngIf="chauffeurPricingForm.controls.hours.errors  && (chauffeurPricingForm.controls.hours.dirty || chauffeurPricingForm.controls.hours.touched)">
          <div [hidden]="!chauffeurPricingForm.controls.hours.errors.required" translate>
            hours
          </div>
          <div [hidden]="!chauffeurPricingForm.controls.hours.errors.pattern" translate>
            invalid_hours
          </div>
        </div>
      </div>

      <div class="form-field col-12 md:col-6 lg:col-6">
        <div class="form-label grid">
          <label for="addHours" translate>label_additional_hours</label>
          <label class="asterisk">*</label>
        </div>
        <div class="grid">
          <input autocomplete="off" type="text" pInputText formControlName="addHours"
            (focus)="settingApiError[0]['additionalMinuteRate']=false" />
        </div>
        <div class="form-errors alert alert-danger" [hidden]='!settingApiError[0]["additionalMinuteRate"]'>
          <div *ngFor="let ErrorMessage of settingApiError['additionalMinuteRate']">
            {{ErrorMessage.message}}
          </div>
        </div>
        <div class="form-errors"
          *ngIf="chauffeurPricingForm.controls.addHours.errors  && (chauffeurPricingForm.controls.addHours.dirty || chauffeurPricingForm.controls.addHours.touched)">
          <div [hidden]="!chauffeurPricingForm.controls.addHours.errors.required" translate>
            add_hours
          </div>
          <div [hidden]="!chauffeurPricingForm.controls.addHours.errors.pattern" translate> invalid_hours
          </div>
        </div>
      </div>

      <div class="form-field col-12 md:col-6 lg:col-6">
        <div class="form-label grid">
          <label for="cancelFee" translate>label_cancellation_fee</label>
          <label class="asterisk">*</label>
        </div>
        <div class="grid">
          <input autocomplete="off" type="text" pInputText formControlName="cancelFee"
            (focus)='settingApiError[0]["cancellationRate"]=false' />
        </div>
        <div class="form-errors alert alert-danger" [hidden]='!settingApiError[0]["cancellationRate"]'>
          <div *ngFor="let ErrorMessage of settingApiError['cancellationRate']">
            {{ErrorMessage.message}}
          </div>
        </div>
        <div class="form-errors"
          *ngIf="chauffeurPricingForm.controls.cancelFee.errors  && (chauffeurPricingForm.controls.cancelFee.dirty || chauffeurPricingForm.controls.cancelFee.touched)">
          <div [hidden]="!chauffeurPricingForm.controls.cancelFee.errors.required" translate>
            cancellation
          </div>
          <div [hidden]="!chauffeurPricingForm.controls.cancelFee.errors.pattern" translate>
            invalid
          </div>
        </div>
      </div>

      <div class="form-field col-12 md:col-6 lg:col-6">
        <div class="form-label grid">
          <label for="transmissionType" translate>label_transmission_type</label>
          <label class="asterisk">*</label>
        </div>
        <p-dropdown [options]="transmissionTypeOptions" [appDisableControl]="corporatePricing"
          formControlName="transmissionType" (onFocus)="settingApiError[0]['vehicleTypeId']=false">
        </p-dropdown>
        <div class="form-errors alert alert-danger" [hidden]='!settingApiError[0]["transmissionType"]'>
          <div *ngFor="let ErrorMessage of settingApiError['transmissionType']">
            {{ErrorMessage.message}}
          </div>
        </div>
        <div class="form-errors"
          *ngIf="chauffeurPricingForm.controls.transmissionType.errors  && (chauffeurPricingForm.controls.transmissionType.dirty || chauffeurPricingForm.controls.transmissionType.touched)">
          <div [hidden]="!chauffeurPricingForm.controls.transmissionType.errors.required" translate>
            transmission_type
          </div>
        </div>
      </div>

      <div class="form-field col-12 md:col-12 lg:col-12">
        <div class="form-errors alert alert-danger" [hidden]='!settingApiError[0]["general"]'>
          <div *ngFor="let ErrorMessage of settingApiError['general']">
            {{ErrorMessage.message}}
          </div>
        </div>
      </div>

      <div class="form-buttons col-12 ui-g-nopad">
        <button class="negative-button" pButton type="button" icon="fa-close" [label]="labelCancel"
          (click)="cancel()"></button>
        <button pButton type="button" [label]="labelSave" icon="fa-check" [disabled]="!chauffeurPricingForm.valid"
          (click)="addPackages()"></button>
      </div>
    </div>
    <p-toast></p-toast>
  </form>
</div>