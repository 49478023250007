import { AppSettings } from './../app.settings';
import { Injectable } from '@angular/core';
import { RestApiService } from './rest-api.service';
import { Observable } from 'rxjs';

@Injectable()
export class NotificationsService {
  canFetchNotification = false;
  constructor(private restApiService: RestApiService) { }


  getDetails(limit, offset): Observable<any> {
    return this.restApiService.get('', '/secure/notification/list?offset=' + offset + '&limit=' + limit, 'page-center');
  }

  getCount(): Observable<any> {
    return this.restApiService.get('', '/secure/notification/unreadcount', 'page-center');
  }

  startFetchingNotifications(callback) {
    AppSettings.CLEAR_NOTIFICATION_INTERVAL = setInterval(() => {
      if (this.canFetchNotification) {
        callback();
      }
    }, AppSettings.NOTIFICATION_INTERVAL);
  }

  stopFetchingNotifications() {
    if (AppSettings.CLEAR_NOTIFICATION_INTERVAL) {
      clearInterval(AppSettings.CLEAR_NOTIFICATION_INTERVAL);
    }
  }

}
