<div (window:resize)="onResize($event)" class="mi-grid-component">
  <div class="search-and-export-data-panel">
    <input autocomplete="off" *ngIf="!bottomSearchBar" type="text" class="grid-search-bar top" [(ngModel)]="search"
      (keyup)="onQuickFilterChanged($event, dt)" placeholder="Press enter to search" />
    <label for="search-bar" class="fa fa-search input-icon"></label>

    <label translate>label_total_records <span>{{totalRecords}}</span></label>

    <span class="export-button top" *ngIf="(exportUrl !== '')">
      <span class="header-action-btn export" (click)="exportGridExcel($event)" title="Download Excel">
        XLS
        <i class="fa fa-fw fa-file-excel-o" aria-hidden="true"></i>
      </span>
    </span>

    <span class="export-button top" *ngIf="(printUrl !== '')">
      <span class="header-action-btn export" (click)="exportGridPdf($event)" title="Download Pdf">
        PDF
        <i class="fa fa-fw fa-file-pdf-o" aria-hidden="true"></i>
      </span>
    </span>
  </div>

  <div class="col-12">
    <p-table #dt [ngClass]="isDetailScreen ? 'detail-screen-available' : ''" [lazy]="true" [rows]="paginationPageSize"
      metaKeySelection="false" [value]="datasource" [paginator]="pagination" [totalRecords]="totalRecords"
      (onLazyLoad)="loadDatasourceLazy($event)" [selectionMode]="rowSelection" (onRowSelect)="onRowSelect($event)"
      (onRowUnselect)="onRowUnselect($event)" [(selection)]="selectedRecords" [resizableColumns]="enableResizer"
      [first]="first" sortMode="multiple" [multiSortMeta]="multiSortMeta" responsiveLayout="stack" class="col-12" 
      [breakpoint]="'400px'">

      <ng-template pTemplate="header">
        <tr>
          <ng-container *ngFor="let col of columnDefs">
            <th [ngStyle]="{ width: col.width ? col.width : '107px' }" *ngIf="col.resize && enableResizer" [ngClass]="col?.class ? col.class : ''" pResizableColumn
              [pSortableColumn]="(col?.sort) ? col.sortId : ''" [width]="col?.width" title="{{col.header}}">
              <p-sortIcon *ngIf="col?.sort" [field]="col.sortId"></p-sortIcon>
              <span *ngIf="col?.header !=='Actions'">{{col.header | uppercase}}</span>
              <span *ngIf="col?.header =='Actions'" class="pl-10">{{col.header | uppercase}}</span>
            </th>

            <th [ngStyle]="{ width: col.width ? col.width : 'auto' }" *ngIf="!col.resize" [ngClass]="col?.class ? col.class : ''"
              [pSortableColumn]="(col?.sort) ? col.sortId : ''" title="{{col.header}}">
              <p-sortIcon *ngIf="col?.sort" [field]="col.sortId"></p-sortIcon>
              <span *ngIf="col?.header !=='Actions'">{{col.header | uppercase}}</span>
              <span *ngIf="col?.header =='Actions'" class="pl-10">{{col.header | uppercase}}</span>
            </th>
          </ng-container>

          <th class="action-column" *ngIf="actionButtonsDefs.length" title="Action" width="150">
            {{'Action' | uppercase}}
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td *ngIf="actionButtonsDefs.length === 0" class="action-header" [attr.colspan]="columnDefs.length">
            No records found
          </td>
          <td *ngIf="actionButtonsDefs.length > 0" class="action-header" [attr.colspan]="columnDefs.length + 1">
            No records found
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columnDefs" let-rowIndex="rowIndex">
        <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" [class]="rowIndex%2==0?'even-row':'odd-row'">

          <td [ngClass]="col?.class ? col.class : ''" *ngFor="let col of columnDefs">
            <div class="eclipse-tooltip" title="{{rowData[col.field]}}" *ngIf="col.cellRendererHtml == undefined">{{rowData[col.field]}}</div>
            <div class="eclipse-tooltip2" title="{{rowData[col.field].innerHTML}}" *ngIf="col.cellRendererHtml != undefined && col?.header !=='Actions'" [innerHtml]="rowData[col.field].innerHTML | keepHtml" [ngClass]="col?.tripId ? col?.tripId : ''">
            </div>

            <div class="eclipse-tooltip2" title="" *ngIf="col.cellRendererHtml != undefined && col?.header =='Actions'" [innerHtml]="rowData[col.field].innerHTML | keepHtml" [ngClass]="col?.tripId ? col?.tripId : ''">
            </div>
          </td>

          <td [ngClass]="col?.class ? col.class : ''" *ngIf="actionButtonsDefs.length">
            <span *ngFor="let btn of actionButtonsDefs">
              <span *ngIf="rowData[btn.field] !== 'deactivated'">
                <button *ngIf="btn.conditionValue === rowData[btn.key]" (click)="actionBtnClick(rowData, btn.class)"
                  [icon]="btn.icon" [title]="btn?.label" [label]="btn?.label" [class]="btn.class" pButton
                  appRipple></button>
              </span>
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>