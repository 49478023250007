<div class="grid form-container panel-div">
  <form [formGroup]="bookingRentalForm" class="col-12">

    <div class="col-12">
      <div class="grid">
        <div class="form-field col-12 md:col-4 lg:col-4">
          <div class="form-label">
            <label for="username" translate>label_pickup_location</label>
            <label class="asterisk">*</label>
          </div>
          <div class="form-control">
            <input autocomplete="off" type="text" id="pickme" [placeholder]="label_pickup" name="pickme" pInputText
              formControlName="pickupLocation" appSearchLocation (setAddress)="setPickup($event)"
              (keyup)="pickupBit=true" (focus)='!packageApiError[0]["pickupLocationName"]=false' />
          </div>
          <div class="form-errors alert alert-danger" [hidden]='!packageApiError[0]["pickupLocationName"]'>
            <div *ngFor="let ErrorMessage of packageApiError['pickupLocationName']">
              {{ErrorMessage.message}}
            </div>
          </div>
          <div class="form-errors"
            *ngIf="bookingRentalForm.controls.pickupLocation.errors  && (bookingRentalForm.controls.pickupLocation.dirty || bookingRentalForm.controls.pickupLocation.touched)">
            <div [hidden]="!bookingRentalForm.controls.pickupLocation.errors.required" translate>
              pickup_location
            </div>
          </div>
        </div>
        <div class="form-field col-12 md:col-4 lg:col-4">
          <div class="form-label">
            <label for="username" translate>label_drop_location</label>
            <label class="asterisk">*</label>
          </div>
          <div class="form-control">
            <input autocomplete="off" type="text" d="dropme" [placeholder]="label_drop" name="dropkme" pInputText
              formControlName="dropLocation" appSearchLocation (setAddress)="setDropUp($event)" />
          </div>
          <div class="form-errors alert alert-danger" [hidden]='!packageApiError[0]["dropLocationName"]'>
            <div *ngFor="let ErrorMessage of packageApiError['dropLocationName']">
              {{ErrorMessage.message}}
            </div>
          </div>
          <div class="form-errors"
            *ngIf="bookingRentalForm.controls.dropLocation.errors  && (bookingRentalForm.controls.dropLocation.dirty || bookingRentalForm.controls.dropLocation.touched)">
            <div [hidden]="!bookingRentalForm.controls.dropLocation.errors.required" translate>
              drop_location
            </div>
          </div>
        </div>

        <div class="form-field col-12 md:col-4 lg:col-4">
          <div class="form-label">
            <label for="username" translate>label_pickup_date_time</label>
            <label class="asterisk">*</label>
          </div>
          <div class="form-control">
            <p-calendar formControlName="pickupDate" [readonlyInput]="true" [defaultDate]="minDate" [minDate]="minDate"
              dateFormat="{{dateFormat}}" [showTime]="true" [showIcon]="true"></p-calendar>
          </div>
          <div class="form-errors alert alert-danger" [hidden]='!packageApiError[0]["pickupDateTime"]'>
            <div *ngFor="let ErrorMessage of packageApiError['pickupDateTime']">
              {{ErrorMessage.message}}
            </div>
          </div>
          <div class="form-errors"
            *ngIf="bookingRentalForm.controls.pickupDate.errors  && (bookingRentalForm.controls.pickupDate.dirty || bookingRentalForm.controls.pickupDate.touched)">
            <div [hidden]="!bookingRentalForm.controls.pickupDate.errors.required" translate>
              booking_date
            </div>
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="form-field col-12 md:col-4 lg:col-4">
          <div class="form-label">
            <label for="serviceType" translate>service_type</label>
          </div>
          <div class="form-control">
            <p-dropdown [options]="serviceTypeOptions" formControlName="serviceType"
              (onChange)="changeServiceType($event)">
            </p-dropdown>
          </div>
        </div>

        <div class="form-field col-12 md:col-4 lg:col-4">
          <div class="form-label">
            <label for="username" translate>label_car_type</label>
            <label class="asterisk">*</label>
          </div>
          <div class="form-control">
            <p-dropdown [options]="carTypeOptions" formControlName="carType" (onChange)="changePackage($event)">
            </p-dropdown>
          </div>
          <div class="form-errors alert alert-danger" [hidden]='!packageApiError[0]["vehicleTypeId"]'>
            <div *ngFor="let ErrorMessage of packageApiError['vehicleTypeId']">
              {{ErrorMessage.message}}
            </div>
          </div>
          <div class="form-errors"
            *ngIf="bookingRentalForm.controls.carType.errors  && (bookingRentalForm.controls.carType.dirty || bookingRentalForm.controls.carType.touched)">
            <div [hidden]="!bookingRentalForm.controls.carType.errors.required" translate>
              car_type
            </div>
          </div>
        </div>

        <div class="form-field col-12 md:col-4 lg:col-4  p-0">
          <div class="form-label col-12 lable-mobile-number">
            <label for="username" translate>label_phone_number</label>
            <label class="asterisk">*</label>
          </div>

          <div class="col-3  ui-inputgroup mobile-number">
            <span class="ui-inputgroup-addon">
              <i class="fa fa-plus"></i>
            </span>
            <input autocomplete="off" class="input-mobile-code" type="text" pInputText formControlName="phoneCode"
              placeholder="Code" />
          </div>

          <div class="form-controlcol-9 mobile-number">
            <p-autoComplete class="assign" [suggestions]="passengerSearchTextResult"
              (completeMethod)="passengerSearch($event)" placeholder="Type to search passenger" field="phoneNum"
              formControlName="mobileNo" (onSelect)="onSelectMobileNumber($event)">
              <ng-template let-ref pTemplate="item">
                <span class="font-16">{{ref.phoneNum}}</span>
              </ng-template>
            </p-autoComplete>
          </div>

          <div class="form-errors alert alert-danger" [hidden]='!packageApiError[0]["numCountryCode"]'>
            <div *ngFor="let ErrorMessage of packageApiError['numCountryCode']">
              {{ErrorMessage.message}}
            </div>
          </div>

          <div class="form-errors"
            *ngIf="bookingRentalForm.controls.phoneCode.errors  && (bookingRentalForm.controls.phoneCode.dirty || bookingRentalForm.controls.phoneCode.touched)">
            <div [hidden]="!bookingRentalForm.controls.phoneCode.errors.required" translate>
              country_code_required
            </div>
            <div [hidden]="!bookingRentalForm.controls.phoneCode.errors.minlength" translate>
              country_code_invalid
            </div>
            <div [hidden]="!bookingRentalForm.controls.phoneCode.errors.maxlength" translate>
              country_code_invalid
            </div>
            <div [hidden]="!bookingRentalForm.controls.phoneCode.errors.pattern" translate>
              country_code_invalid
            </div>
          </div>

          <div class="form-errors alert alert-danger" [hidden]='!packageApiError[0]["phoneNum"]'>
            <div *ngFor="let ErrorMessage of packageApiError['phoneNum']">
              {{ErrorMessage.message}}
            </div>
          </div>

          <div class="form-errors"
            *ngIf="bookingRentalForm.controls.mobileNo.errors  && (bookingRentalForm.controls.mobileNo.dirty || bookingRentalForm.controls.mobileNo.touched)">
            <div [hidden]="!bookingRentalForm.controls.mobileNo.errors.required" translate>
              phone_invalid
            </div>
            <div [hidden]="!bookingRentalForm.controls.mobileNo.errors.minlength" translate>
              phone_invalid
            </div>
            <div [hidden]="!bookingRentalForm.controls.mobileNo.errors.maxlength" translate>
              max_length
            </div>
            <div [hidden]="!bookingRentalForm.controls.mobileNo.errors.pattern" translate>
              number_pattern
            </div>
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="form-field col-12 md:col-4 lg:col-4">
          <div class="form-label">
            <label for="username" translate>email</label>
            <label class="asterisk">*</label>
          </div>
          <div class="form-control">
            <input autocomplete="off" type="text" pInputText formControlName="email" />
          </div>
          <div class="form-errors alert alert-danger" [hidden]='!packageApiError[0]["email"]'>
            <div *ngFor="let ErrorMessage of packageApiError['email']">
              {{ErrorMessage.message}}
            </div>
          </div>
          <div class="form-errors"
            *ngIf="bookingRentalForm.controls.email.errors  && (bookingRentalForm.controls.email.dirty || bookingRentalForm.controls.email.touched)">
            <div [hidden]="!bookingRentalForm.controls.email.errors.required" translate>
              email_required
            </div>
            <div [hidden]="!bookingRentalForm.controls.email.errors.pattern" translate>
              email_pattern
            </div>
          </div>
        </div>

        <div class="form-field col-12 md:col-4 lg:col-4">
          <div class="form-label">
            <label for="username" translate>label_passenger_name</label>
            <label class="asterisk">*</label>
          </div>
          <div class="form-control">
            <input autocomplete="off" type="text" pInputText formControlName="passengerName" />
          </div>
          <div class="form-errors alert alert-danger" [hidden]='!packageApiError[0]["passengerName"]'>
            <div *ngFor="let ErrorMessage of packageApiError['passengerName']">
              {{ErrorMessage.message}}
            </div>
          </div>
          <div class="form-errors"
            *ngIf="bookingRentalForm.controls.passengerName.errors  && (bookingRentalForm.controls.passengerName.dirty || bookingRentalForm.controls.passengerName.touched)">
            <div [hidden]="!bookingRentalForm.controls.passengerName.errors.required" translate>
              passenger_name
            </div>
            <div [hidden]="!bookingRentalForm.controls.passengerName.errors.minlength" translate>
              min_length
            </div>
            <div [hidden]="!bookingRentalForm.controls.passengerName.errors.pattern" translate>
              name_pattern
            </div>
          </div>
        </div>

        <div class="form-field col-12 md:col-4 lg:col-4">
          <div class="form-label">
            <label for="username" translate>label_packages</label>
            <label class="asterisk">*</label>
          </div>
          <div class="form-control">
            <p-dropdown [options]="packageOptions" formControlName="package" filter="filter">
            </p-dropdown>
          </div>
          <div class="form-errors alert alert-danger" [hidden]='!packageApiError[0]["packageId"]'>
            <div *ngFor="let ErrorMessage of packageApiError['packageId']">
              {{ErrorMessage.message}}
            </div>
          </div>
          <div class="form-errors"
            *ngIf="bookingRentalForm.controls.package.errors  && (bookingRentalForm.controls.package.dirty || bookingRentalForm.controls.package.touched)">
            <div [hidden]="!bookingRentalForm.controls.package.errors.required" translate>
              Package_req
            </div>
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="form-field col-12 md:col-4 lg:col-4">
          <div class="form-label">
            <label for="roomNum" translate>label_room_num</label>
            <label class="asterisk">*</label>
          </div>
          <div class="form-control">
            <input autocomplete="off" type="text" pInputText formControlName="roomNum" />
          </div>
          <div class="form-errors alert alert-danger" [hidden]='!packageApiError[0]["roomNum"]'>
            <div *ngFor="let ErrorMessage of packageApiError['roomNum']">
              {{ErrorMessage.message}}
            </div>
          </div>
          <div class="form-errors"
            *ngIf="bookingRentalForm.controls.roomNum.errors && (bookingRentalForm.controls.roomNum.dirty || bookingRentalForm.controls.roomNum.touched)">
            <div [hidden]="!bookingRentalForm.controls.roomNum.errors.required" translate>
              lable_room_no_req
            </div>
            <div [hidden]="!bookingRentalForm.controls.roomNum.errors.maxlength" translate>
              room_num_max_length
            </div>
          </div>
        </div>

        <div class="form-field col-12 md:col-4 lg:col-4">
          <div class="form-label">
            <label for="username" translate>label_Hotels</label>
            <label class="asterisk">*</label>
          </div>
          <div class="form-control">
            <p-dropdown [options]="hotelList" formControlName="hotelNames">
            </p-dropdown>
          </div>
          <div class="form-errors alert alert-danger" [hidden]='!packageApiError[0]["hotelNames"]'>
            <div *ngFor="let ErrorMessage of packageApiError['hotelNames']">
              {{ErrorMessage.message}}
            </div>
          </div>
          <div class="form-errors"
            *ngIf="bookingRentalForm.controls.hotelNames.errors  && (bookingRentalForm.controls.hotelNames.dirty || bookingRentalForm.controls.hotelNames.touched)">
            <div [hidden]="!bookingRentalForm.controls.hotelNames.errors.required" translate>
              hotel_req
            </div>
          </div>
        </div>

        <div class="form-field col-12 md:col-4 lg:col-4">
          <div class="form-label">
            <label for="quotationAmount" translate>label_quotation_amount_accepted</label>
            <label class="asterisk">*</label>
          </div>
          <div class="form-control">
            <input autocomplete="off" type="text" pInputText formControlName="quotationAmount" />
          </div>
          <div class="form-errors alert alert-danger" [hidden]='!packageApiError[0]["quotationAmount"]'>
            <div *ngFor="let ErrorMessage of packageApiError['quotationAmount']">
              {{ErrorMessage.message}}
            </div>
          </div>
          <div class="form-errors"
            *ngIf="bookingRentalForm.controls.quotationAmount.errors  && (bookingRentalForm.controls.quotationAmount.dirty || bookingRentalForm.controls.quotationAmount.touched)">
            <div [hidden]="!bookingRentalForm.controls.quotationAmount.errors.required" translate>
              quotation_amount_req
            </div>
            <div [hidden]="!bookingRentalForm.controls.quotationAmount.errors.pattern" translate>
              invalid_amount
            </div>
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="form-field col-12 md:col-4 lg:col-4">
          <div class="form-label">
            <label for="note" translate>label_note</label>
          </div>
          <div class="form-control">
            <input autocomplete="off" type="text" pInputText formControlName="note" />
          </div>
          <div class="form-errors alert alert-danger" [hidden]='!packageApiError[0]["note"]'>
            <div *ngFor="let ErrorMessage of packageApiError['note']">
              {{ErrorMessage.message}}
            </div>
          </div>
        </div>
        <div [hidden]="!isCorporatePassenger" class="col-12 md:col-4 lg:col-4">
          <div class="form-label">
            <label for="note" translate>label_corporate_name</label>
          </div>
          <div class="disguise-read-only">
            {{selectedPassengerCorporate}}
          </div>
        </div>
        <div class="form-field col-12 md:col-4 lg:col-4">
          <div class="form-control p-6-percent">
            <p-checkbox class="vip-chk" name="VPassenger" label="VIP Passenger" binary="true"
              formControlName="VIPPassenger"></p-checkbox>
          </div>
          <div class="form-errors alert alert-danger" [hidden]='!packageApiError[0]["vip"]'>
            <div *ngFor="let ErrorMessage of packageApiError['vip']">
              {{ErrorMessage.message}}
            </div>
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="form-field col-12 md:col-12 lg:col-12">
          <div class="form-errors alert alert-danger" [hidden]='!packageApiError[0]["general"]'>
            <div *ngFor="let ErrorMessage of packageApiError['general']">
              {{ErrorMessage.message}}
            </div>
          </div>
        </div>
      </div>

      <div class="form-buttons col-12 p-0">
        <button class="negative-button" pButton type="button" icon="fa-close" [label]="labelCancel"
          (click)="cancel()"></button>
        <button pButton type="button" [label]="labelSave" icon="fa-check" [disabled]="!bookingRentalForm.valid"
          (click)="addbooking()"></button>
      </div>
    </div>

    <p-toast></p-toast>
  </form>
</div>