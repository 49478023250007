<div class="trip-details" *ngIf="tripDetails!==null">
  <div class="grid panel-div">
    <div class="col-6 md:col-6 lg:col-6 ui-g-nopad">
      <div class="grid">
        <div class="col-12">
          <span class="info-label" translate>label_status</span>: {{tripDetails.bookingStatusStr }}
        </div>
        <div class="col-12">
          <div>{{ tripDetails.partnerType }} <span translate>label_booking</span></div>
        </div>
      </div>
    </div>
    <div class="col-6 md:col-6 lg:col-6 ui-g-nopad">
      <div class="grid">
        <div class="col-12">
          <span class="info-label" translate>label_trip_id</span> {{tripDetails.bookingCode }}
        </div>
        <div class="col-12">
          <span class="info-label" translate>label_service_id</span> {{tripDetails.serviceType }}
        </div>
      </div>
    </div>

    <div class="col-12">
      <span class="info-label" translate>label_pickup_date_time</span> {{tripDetails.pickupDateTime|datetimeformat}}
    </div>

    <div class="col-12">
      <div class="col-6 md:col-6 lg:col-6 ui-g-nopad">
        <span class="info-label" translate>label_vehicle_type</span> {{tripDetails.vehicleType }}
      </div>
      <div class="col-6 md:col-6 lg:col-6 ui-g-nopad">
        <span class="info-label" translate *ngIf='tripDetails.passangerCount!=null'>label_no_of_passenger</span> {{tripDetails.passangerCount
        }}
      </div>
    </div>
    <div class="col-12" *ngIf=" tripDetails.note!=undefined && tripDetails.note!=null && tripDetails.note!=''">
      <button type="button" (click)="showDialog()" pButton icon="fa-external-link-square" label="View Note"></button>
    </div>

    <div class="col-12">
      <span class="pickup-circle">&#9673;</span> {{tripDetails.pickupLocationName }}
    </div>
    <div class="col-12">
      <span class="drop-circle">&#9673;</span> {{tripDetails.dropLocationName }}
    </div>
  </div>

  <p-dialog [blockScroll]="true" header="Note" [(visible)]="display" modal="modal" [style]="{width: '300px'}"
    [responsive]="true">
    <p *ngIf="tripDetails.note!=null">
      {{tripDetails.note}}
    </p>
  </p-dialog>

  <p-toast></p-toast>
</div>