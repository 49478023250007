import * as moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';
import { AppSettings } from '../app.settings';
@Pipe({
  name: 'minutsformat'
})
export class MinutsFormatPipe implements PipeTransform {

  transform(value: any, args?: any): string {
    let format = AppSettings.TIME_FORMATTER;
    if (args !== undefined) {
      format = args;
    }
    if (value > 0) {
      return moment(value).format(AppSettings.TIME_FORMATTER);
    } else {
      return '--:--';
    }
  }

}
