import { Component, OnInit, Input, OnChanges, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { CommonBindingDataService } from './../../../shared/services/common-binding-data.service';
import { Message, MessageService } from 'primeng/api';

@Component({
  selector: 'app-comment-info',
  templateUrl: './comment-info.component.html'
})
export class CommentInfoComponent implements OnInit, OnChanges {
  message: Message[] = [];
  @Input() tripCommentDetails = null;
  @Output() isStatusChange: EventEmitter<any> = new EventEmitter();

  reply;
  display;
  userType;
  rating;

  constructor(
    private commonService: CommonBindingDataService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.reply = this.commonService.getLabel('label_reply');
  }

  ngOnChanges(samplechange: SimpleChanges) {
    if (this.tripCommentDetails !== null) {
      if (this.tripCommentDetails.driverRating !== undefined
        && this.tripCommentDetails.driverRating !== null
        && this.tripCommentDetails.driverRating !== '') {
        this.rating = parseInt(this.tripCommentDetails.driverRating, 10);
        if (this.rating < this.tripCommentDetails.driverRating) {
          this.rating = this.rating + 1;
        }
        this.tripCommentDetails.driverRating = this.rating;
      }
    }
  }

  onReply(data) {
    this.userType = data;
    this.display = true;
  }

  isPopCancel(event) {
    this.display = !this.display;
  }

  isPopSave(event) {
    this.display = !this.display;
    event.general.forEach(element => {
      this.message = [];
      this.messageService.add({ severity: 'success', summary: 'Success', detail: element.message });
    });
    this.isStatusChange.emit();
  }

}
