<p-dialog [blockScroll]="true" [header]="labelCancelBooking" [(visible)]="cancelQuotationView" modal="modal"
  [style]="{width: '300px'}" [responsive]="true" [closable]="false">
  <div class="grid">
    <div class="form-field col-12 md:col-12 lg:col-12">
      <div class="form-label">
        <label for="quotationnote" translate>label_qoutation_reason_for_cancel</label>
      </div>
      <div class="form-control">
        <textarea pInputTextarea [(ngModel)]="quotationNote" placeholder="add note"></textarea>
        <div class="form-errors" *ngIf="false" translate>
          error_quotation_note
        </div>
      </div>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton icon="fa-check" (click)="onSave()" [label]="labelCancelBooking"></button>
    <button class="negative-button " pButton type="button" icon="fa-close" [label]="labelCancel"
      (click)="onCancel()"></button>
  </p-footer>
</p-dialog>

<p-toast></p-toast>