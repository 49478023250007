import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserCommonService } from '../../modules/shared/services/user-common.service';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { AppSettings } from '../../modules/shared/app.settings';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
declare const google: any;

@Component({
  selector: 'app-track-my-location',
  templateUrl: './track-my-location.component.html'
})

export class TrackMyLocationComponent implements OnInit, OnDestroy {
  map: any;
  marker;
  markers = [];
  directionsService;
  directionsDisplay;
  maxRating = AppSettings.Max_Rating;
  locationDetails: any = null;
  bookingId;
  tourId;
  source;
  destination;
  current;
  liveLocationInterval;
  message: Message[] = [];
  tourStatus;
  tourStatusCode = 0;
  END_TRIP = 140;
  isMapPointChanged = false;
  isFromSource = true;
  profileImageUrl: string = AppSettings.GET_FILE_THUMB_URL;
  imageUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
  showTrackMyLocationPage = false;
  errorMessage = '';
  currentStatus: Number;
  googleIntevalCheck;

  constructor(
    private httpClient: HttpClient,
    private commonBindingDataService: CommonBindingDataService,
    private userCommonService: UserCommonService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.userCommonService.getAdminUserMetaData();
    this.googleIntevalCheck = setInterval(() => {
      if (google) {
        this.initProcess();
      }
    }, 1000);
  }

  prependApiUrl(url: string): string {
    return AppSettings.BASE_URL + '/' + AppSettings.TENANT + url;
  }

  initProcess() {
    clearInterval(this.googleIntevalCheck);
    this.googleIntevalCheck = 0;
    this.bookingId = this.route.snapshot.queryParams['id'];
    this.directionsDisplay = new google.maps.DirectionsRenderer;
    this.userCommonService.getTrackMyLocationDetails(this.bookingId).subscribe((result) => {
      this.showTrackMyLocationPage = true;
      this.locationDetails = result;
      this.tourStatus = result.tourBookingDetailsDto.tourBookingInfo.tourStatusString;
      this.tourStatusCode = result.tourBookingDetailsDto.tourBookingInfo.tourStatus;
      this.tourId = result.tourBookingDetailsDto.tourBookingInfo.id;
      this.currentStatus = this.tourStatusCode;
      if (this.tourStatusCode !== this.END_TRIP) {
        this.getLiveData();
      } else {
        this.source = new google.maps.LatLng(result.tourBookingDetailsDto.tourBookingInfo.sourceLatitude, result.tourBookingDetailsDto.tourBookingInfo.sourceLongitude);
        this.destination = new google.maps.LatLng(result.tourBookingDetailsDto.tourBookingInfo.destinationLatitude, result.tourBookingDetailsDto.tourBookingInfo.destinationLongitude);
        this.isFromSource = false;
        this.updateLocation();
      }
      if (result.tourBookingDetailsDto.driverInfo.profileImage !== '' && result.tourBookingDetailsDto.driverInfo.profileImage !== null) {
        this.httpClient.get(this.prependApiUrl('/file/thumbnail_' + result.tourBookingDetailsDto.driverInfo.profileImage))
          .subscribe(response => {
            if (response['imageString'].length !== 20) {
              this.imageUrl = <any>this.sanitizer.bypassSecurityTrustUrl(response['imageString']);
            }
          });
      }

      this.calculateAndDisplayRoute();
      this.map = new google.maps.Map(document.getElementById('trackLocationMap'), {
        center: this.destination,
        zoom: 6,
        gestureHandling: AppSettings.COOPERATIVE,
        zoomControl: true
      });
      this.trackLiveLocation();
    }, (reason) => {
      this.errorMessage = this.commonBindingDataService.getLabel('error_not_exist_page');
    });
  }

  trackLiveLocation() {
    const trackLocationModel = this;
    if (this.tourStatusCode !== this.END_TRIP) {
      this.liveLocationInterval = setInterval(function() {
        trackLocationModel.getLiveData();
      }, 1000 * 15);
    }
  }

  ngOnDestroy() {
    clearInterval(this.liveLocationInterval);
  }

  getLiveData() {
    this.userCommonService.getTrackMyLiveLocation(this.tourId).subscribe((result) => {
      this.source = new google.maps.LatLng(result.sourceLatitude, result.sourceLongitude);
      this.destination = new google.maps.LatLng(result.destinationLatitude, result.destinationLongitude);
      this.current = new google.maps.LatLng(result.driverLocationLatitude, result.driverLocationLongitude);
      this.tourStatus = result.tourStatusString;
      this.currentStatus = result.tourStatus;
      this.isMapPointChanged = (this.isFromSource !== result.trackFromsourse) ? true : false;
      this.isFromSource = result.trackFromsourse;
      this.updateLocation();
    }, (reason) => {
    });
  }

  updateLocation() {
    this.clearMarker();
    if (this.currentStatus === this.END_TRIP) {
      this.setMarker(this.map, this.current, 'driver');
    } else {
      this.calculateAndDisplayRoute();
      clearInterval(this.liveLocationInterval);
    }

    if (!this.isFromSource) {
      this.setMarker(this.map, this.destination, 'destination');
    }
    this.setMarker(this.map, this.source, 'source');

    if (this.isMapPointChanged) {
      this.calculateAndDisplayRoute();
    }
  }

  calculateAndDisplayRoute() {
    if (this.directionsDisplay !== null) {
      this.directionsDisplay.setMap(null);
      this.directionsDisplay = null;
    }

    this.directionsDisplay = new google.maps.DirectionsRenderer({
      polylineOptions: {
        strokeColor: AppSettings.DEFAULT_MAP_ROUTE_COLOR
      },
      suppressMarkers: true
    });
    this.directionsDisplay.setMap(this.map);
    this.directionsService = new google.maps.DirectionsService;
    this.directionsService.route({
      origin: this.currentStatus !== this.END_TRIP ? this.current : this.source,
      destination: this.isFromSource ? this.source : this.destination,
      travelMode: AppSettings.DRIVING,
      provideRouteAlternatives: false
    }, (response, status) => {
      if (status === 'OK') {
        this.clearMarker();
        this.directionsDisplay.setDirections(response);
        this.directionsDisplay.setMap(this.map);
        if (this.currentStatus !== this.END_TRIP) {
          this.setMarker(this.map, this.current, 'driver');
        }
        if (!this.isFromSource) {
          this.setMarker(this.map, this.destination, 'destination');
        }
        this.setMarker(this.map, this.source, 'source');
      } else {
        this.mapMessage(status);
      }
    });
  }

  setMarker(map, location, type) {
    let icon;
    switch (type) {
      case 'source':
        icon = '/assets/images/temp/Source.png';
        break;
      case 'destination':
        icon = '/assets/images/temp/Destination.png';
        break;
      default:
        icon = '/assets/images/temp/taxi_free.png';
        break;
    }
    this.marker = new google.maps.Marker;
    this.marker.setMap(map);
    this.marker.setIcon(icon);
    this.marker.setPosition(location);
    this.markers.push(this.marker);
  }

  clearMarker() {
    for (let marker = 0; marker < this.markers.length; marker++) {
      this.markers[marker].setMap(null);
    }
  }

  mapMessage(status) {
    this.message = [];
    this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: status });
  }

}
