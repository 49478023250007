<div class="col-6 md:col-12">
  <div class="activity-box">
    <div class="grid activity-header-area">
      <div class="col-6 ui-g-nopad">
        <h4 class="activity-header">{{headerLHS}}</h4>
      </div>
      <div class="col-6 ui-g-nopad">
        <h4 class="show-all-notifs" (click)="viewAllNotifications()" translate>view_all</h4>
      </div>
    </div>
    <div class="activity-body-area hide-extra">
      <div class="col-12">
        <div class="col-12">
          <app-mi-grid-v2 [url]="url" [columnDefs]="columns" [paginationPageSize]="5" [pagination]="false"
            rowSelection="multiple" divClass="mi-grid"></app-mi-grid-v2>
        </div>
      </div>
    </div>
  </div>
</div>