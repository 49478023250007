<div class="no-content" *ngIf="notifications.length==0">
  {{noNotificationFoundLabel}}
</div>

<div class="scroll-container">
  <div class="grid notification-card ui-g-nopad" *ngFor="let notification of notifications">
    <div class="col-12 notification-time">
      <div class="grid">
        <div class="col-6 date ui-g-nopad">
          {{notification.createdAt| dateformat}}
        </div>
        <div class="col-6 ago ui-g-nopad">
          {{notification.createdAt| ago }}
        </div>
      </div>

      <div class="col-12 message">
        {{notification.message}}
      </div>
    </div>
  </div>

  <div class="col-12" *ngIf="notificationCount > notifications.length">
    <a class="load-more-btn" (click)="onLoadMore()">
      <i aria-hidden="true" class="fa fa-plus-circle"></i>
      <span class="pl-5" translate>label_show_more</span>
    </a>

  </div>

</div>