import { SalesQuotationTripInfoModal } from './sale-quotation-trip-info-modal';
import { SalesQuotationVehicleInfoModal } from './sale-qutation-vehicle-info-modal';
import { SalesQuotationDriverAndPassengerInfoModal } from './sale-quotation-driver-and-passenger-info-modal';

export class SalesQuotationBookingDetailsModal {
  driverInfo: SalesQuotationDriverAndPassengerInfoModal = null;
  passengerInfo: SalesQuotationDriverAndPassengerInfoModal = null;
  vehicleInfo: SalesQuotationVehicleInfoModal = null;
  salesQuotationDetails: SalesQuotationTripInfoModal = null;

  constructor(option?: {
    driverInfo: SalesQuotationDriverAndPassengerInfoModal;
    passengerInfo: SalesQuotationDriverAndPassengerInfoModal;
    vehicleInfo: SalesQuotationVehicleInfoModal;
    salesQuotationDetails: SalesQuotationTripInfoModal;
  }) {
    if (option) {
      this.driverInfo = option.driverInfo;
      this.passengerInfo = option.passengerInfo;
      this.vehicleInfo = option.vehicleInfo;
      this.salesQuotationDetails = option.salesQuotationDetails;
    }
  }
}
