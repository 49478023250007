<div class="driver-details" *ngIf="passengerDetails != null">
  <div class="grid panel-div">
    <div class="col-3 md:col-3 lg:col-3 sm:col-12">
      <img class="user-pic" src="{{imageUrl}}" onerror="src='/assets/images/temp/default_profile.png'" />
      <div class="center-align">
        <p-rating [ngModel]="Rating" readonly="true" stars="5" [cancel]="false" class="font-21"></p-rating>
      </div>
    </div>
    <div class="col-9 md:col-9 lg:col-9 ui-g-nopad mrg-top-10">
      <div class="grid">
        <div class="col-12">
          <div class="info-label" translate>label_passenger_details_header</div>
        </div>
        <div class="col-3">
          <span class="info-label" translate>label_name</span>
          <span class="info-label">: </span>
        </div>
        <div class="col-9">
          <div>{{passengerDetails.firstName }} {{ passengerDetails.lastName}}</div>
        </div>
        <div class="col-3">
          <div class="info-label" translate>label_phone_number</div>
        </div>
        <div class="col-9">
          <div>
            <span *ngIf="passengerDetails.numCountryCode!=null">{{passengerDetails.numCountryCode}} {{
              passengerDetails.phoneNum }}</span>
          </div>
        </div>
        <div class="col-3">
          <div class="info-label" translate>label_email</div>
        </div>
        <div class="col-9">
          <div>{{ passengerDetails.email }}</div>
        </div>
        <div class="col-12 ui-g-nopad" *ngIf='passengerDetails.rating != null'>
          <div class="col-3">
            <div class="info-label" translate>rating_col</div>
          </div>
          <div class="col-9">
            <div>{{ passengerDetails.passengerRating }}</div>
          </div>
        </div>

        <div class="col-12 ui-g-nopad" *ngIf='passengerDetails.addressLine1 != null && passengerDetails.addressLine1 != undefined && passengerDetails.addressLine1 != ""'>
          <div class="col-3">
            <div class="info-label" translate>address_col</div>
          </div>
          <div class="col-9">
            <div>
              <span>{{ passengerDetails.addressLine1 }}</span>
              <span *ngIf='passengerDetails.addressLine2!= null && passengerDetails.addressLine2!= undefined && passengerDetails.addressLine2 != ""'>
                {{passengerDetails.addressLine2}}
              </span>
              <span *ngIf='city != "" && city != undefined'>, {{city}}</span>
              <span>, {{country}}</span>
              <span>, {{passengerDetails.zipcode}}</span>
            </div>
          </div>
        </div>

        <div class="col-12 ui-g-nopad" *ngIf='bookingsCount != null'>
          <div class="col-3">
            <div class="info-label" translate>noOfBookings</div>
          </div>
          <div class="col-9">
            <div>{{ bookingsCount }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="grid panel-div" *ngIf="passengerDetails == null">
  <div class="col-12">
    <div class="info-label" translate>message_passenger_not_assigned</div>
  </div>
</div>