import { Observable } from 'rxjs';
import { RestApiService } from './../../shared/services/rest-api.service';
import { Injectable } from '@angular/core';

@Injectable()
export class SalesQuotationBookingDetailsService {
  bookingDetails: any;

  constructor(private restApiService: RestApiService) { }

  getSaleQuatationDetails(quotationId): Observable<any> {
    return this.restApiService.get('', '/secure/sales-quotations/' + quotationId, 'page-center');
  }

  uploadQuotation(quotationId, data): Observable<any> {
    return this.restApiService.put('', '/secure/sales-quotations/' + quotationId + '/upload', data, 'page-center');
  }

  cancelQuotation(quotationId, data): Observable<any> {
    return this.restApiService.put('', '/secure/sales-quotations/' + quotationId + '/cancel', data, 'page-center');
  }
}
