import { NavtabComponent } from './components/navtab/navtab.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule, ModuleWithProviders } from '@angular/core';
import {
  TranslateModule, TranslateLoader, TranslateService, MissingTranslationHandler
} from '@ngx-translate/core';
import { TripMapComponent } from './components/trip-map/trip-map.component';
import { UploadQuoteComponent } from './components/upload-quote/upload-quote.component';
import { TripDetailsComponent } from './components/trip-details/trip-details.component';
import { BookingHistoryTripDetailsComponent } from './components/booking-history-trip-details/booking-history-trip-details.component';
import { BookingUserDetailsComponent } from './components/booking-user-details/booking-user-details.component';
import { BookingDriverDetailsComponent } from './components/driver-details/booking-driver-details.component';
import { CancelSaleQuotationComponent } from './components/cancel-sale-quotation/cancel-sale-quotation.component';
import { BookingCancelationComponent } from './components/booking-cancelation/booking-cancelation.component';
import { MenuModule } from 'primeng/menu';
import { ChartModule } from 'primeng/chart';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { EditorModule } from 'primeng/editor';
import { RatingModule } from 'primeng/rating';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlockUIModule } from 'primeng/blockui';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { ConfirmationService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { PanelMenuModule } from 'primeng/panelmenu';
import { FileUploadModule } from 'primeng/fileupload';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SidebarModule } from 'primeng/sidebar';
import { UiService } from './services/ui.service';
import { RestApiService } from './services/rest-api.service';
import { UserCommonService } from './services/user-common.service';
import { StorageService } from './services/storage.service';
import { ErrorBindingService } from './services/error-binding.service';
import { CommonBindingDataService } from './services/common-binding-data.service';
import { SidebarService } from './services/sidebar.service';
import { AuthenticationValidationService } from './services/authentication-validation.service';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { MiTranslateLoaderService } from './services/mi-translate-loader.service';
import { MiMissingTranslationHandlerService } from './services/mi-missing-translation-handler.service';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { AssignSearchComponent } from './components/assign-search/assign-search.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { DashboardStatsCountsComponent } from './components/dashboard-stats-counts/dashboard-stats-counts.component';
import { DashboardActivityComponent } from './components/dashboard-activity/dashboard-activity.component';
import { LoaderService } from './components/loader/loader.service';
import { LoaderComponent } from './components/loader/loader.component';
import { FileUploaderComponent } from './components/file-uploader/file.uploader.component';
import { AgoPipe } from './pipes/ago.pipe';
import { DateFormatPipe } from './pipes/dateformat.pipe';
import { DateTimeFormatPipe } from './pipes/datetimeformat.pipe';
import { MinutsFormatPipe } from './pipes/minutesformat.pipe';
import { TotalAndActiveCountsComponent } from './components/total-and-active-counts/total-and-active-counts.component';
import { NotificationMG1Component } from './components/notification-mg1/notification-mg1.component';
import { CommentInfoComponent } from './components/comment-info/comment-info.component';
import { CommentReplyComponent } from './components/comment-reply/comment-reply.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { BookingHistoryDetailsComponent } from './components/booking-history-details/booking-history-details.component';
import { SearchLocationDirective } from './directives/search-location.directive';
import { DisableControlDirective } from './directives/input-disabled.directive';
import {
  EditablePricingChauffeurComponent
} from 'app/modules/shared/components/editable-pricing-chauffeur/editable-pricing-chauffeur.component';
import { OtpVerifiyComponent } from './components/otp-verifiy/otp-verifiy.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { EditTaxiPartnerPricingComponent } from './components/edit-taxi-partner-pricing/edit-taxi-partner-pricing.component';
import { RentalDocsComponent } from './components/rental-docs/rental-docs.component';
import { BookTaxiComponent } from './components/book-taxi/book-taxi.component';
import { BookRentalComponent } from './components/book-rental/book-rental.component';
import { RecentEmergencyRequestComponent } from './components/recent-emergency-request/recent-emergency-request.component';
import { OtpComponent } from './components/otp/otp.component';
import { TrackLocationComponent } from './components/track-location/track-location.component';
import { AccessProviderDirective } from './directives/access-provider.directive';
import { PasswordModule } from 'primeng/password';
import { MiGrid2Component } from './components/mi-grid-2/mi-grid-2.component';
import { TableModule } from 'primeng/table';
import { KeepHtmlPipe } from './pipes/keep-html.pipe';
import { AssignDriverComponent } from './components/assign-driver/assign-driver.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MiGridMultisortComponent } from './components/mi-grid-multisort/mi-grid-multisort.component';
import { WhiteSpaceDirective } from './directives/white-space.directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PanelMenuModule,
    RatingModule,
    ButtonModule,
    EditorModule,
    ConfirmDialogModule,
    CalendarModule,
    ToastModule,
    ChartModule,
    MenuModule,
    DropdownModule,
    RadioButtonModule,
    CheckboxModule,
    DialogModule,
    FormsModule,
    FileUploadModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    TableModule,
    BlockUIModule,
    OverlayPanelModule,
    TabMenuModule,
    TabViewModule,
    SidebarModule,
    PasswordModule,
    ProgressSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: MiTranslateLoaderService
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MiMissingTranslationHandlerService
      }
    }),
  ],

  declarations: [
    HeaderComponent,
    SidebarComponent,
    PageHeaderComponent,
    DisableControlDirective,
    SearchLocationDirective,
    ChangePasswordComponent,
    EditProfileComponent,
    KeepHtmlPipe,
    AssignSearchComponent,
    PieChartComponent,
    BarChartComponent,
    DashboardStatsCountsComponent,
    DashboardActivityComponent,
    LoaderComponent,
    FileUploaderComponent,
    AgoPipe,
    DateFormatPipe,
    DateTimeFormatPipe,
    MinutsFormatPipe,
    TotalAndActiveCountsComponent,
    BookingDriverDetailsComponent,
    BookingUserDetailsComponent,
    TripDetailsComponent,
    TripMapComponent,
    UploadQuoteComponent,
    NotificationMG1Component,
    CommentInfoComponent,
    CommentReplyComponent,
    ForgotPasswordComponent,
    OtpVerifiyComponent,
    BookingHistoryDetailsComponent,
    BookingHistoryTripDetailsComponent,
    CancelSaleQuotationComponent,
    EditablePricingChauffeurComponent,
    OtpVerifiyComponent,
    SetPasswordComponent,
    EditTaxiPartnerPricingComponent,
    RentalDocsComponent,
    NavtabComponent,
    BookTaxiComponent,
    BookRentalComponent,
    BookingCancelationComponent,
    RecentEmergencyRequestComponent,
    OtpComponent,
    TrackLocationComponent,
    AccessProviderDirective,
    MiGrid2Component,
    AssignDriverComponent,
    MiGridMultisortComponent,
    WhiteSpaceDirective
  ],

  exports: [
    HeaderComponent,
    SidebarComponent,
    PageHeaderComponent,
    TranslateModule,
    ConfirmDialogModule,
    SearchLocationDirective,
    AccessProviderDirective,
    DisableControlDirective,
    AssignSearchComponent,
    PieChartComponent,
    BarChartComponent,
    DashboardStatsCountsComponent,
    DashboardActivityComponent,
    LoaderComponent,
    FileUploaderComponent,
    AgoPipe,
    DateFormatPipe,
    DateTimeFormatPipe,
    KeepHtmlPipe,
    MinutsFormatPipe,
    TotalAndActiveCountsComponent,
    BookingDriverDetailsComponent,
    BookingUserDetailsComponent,
    TripDetailsComponent,
    TripMapComponent,
    UploadQuoteComponent,
    NotificationMG1Component,
    CommentInfoComponent,
    ForgotPasswordComponent,
    OtpVerifiyComponent,
    BookingHistoryDetailsComponent,
    BookingHistoryTripDetailsComponent,
    CancelSaleQuotationComponent,
    EditablePricingChauffeurComponent,
    SetPasswordComponent,
    EditTaxiPartnerPricingComponent,
    RentalDocsComponent,
    NavtabComponent,
    BookTaxiComponent,
    BookRentalComponent,
    BookingCancelationComponent,
    RecentEmergencyRequestComponent,
    OtpComponent,
    MiGrid2Component,
    TrackLocationComponent,
    AssignDriverComponent,
    MiGridMultisortComponent,
    WhiteSpaceDirective,
    ChartModule
  ]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        LoaderService,
        RestApiService,
        UiService,
        UserCommonService,
        StorageService,
        ErrorBindingService,
        CommonBindingDataService,
        SearchLocationDirective,
        DisableControlDirective,
        AuthenticationValidationService,
        ConfirmationService,
        TranslateService,
        SidebarService,
        WhiteSpaceDirective
      ]
    };
  }

  constructor(translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');
  }

}
