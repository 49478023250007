import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-booking-history-trip-details',
  templateUrl: './booking-history-trip-details.component.html'
})

export class BookingHistoryTripDetailsComponent {
  @Input() tripDetails;
  display = false;

  showDialog() {
    this.display = true;
  }

}
