import { Component, OnInit } from '@angular/core';
import { AppSettings } from './modules/shared/app.settings';
import { Router, NavigationEnd } from '@angular/router';
import { UserCommonService } from './modules/shared/services/user-common.service';
import { NotificationsService } from './modules/shared/services/notifications.service';
import { StorageService } from './modules/shared/services/storage.service';
declare const google: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  title = 'NEMT';
  showHeaderAndSidebar = false;
  isMetadataLoaded: any = false;
  googleIntevalCheck;
  isReady = false;

  constructor(
    private router: Router,
    public notificationService: NotificationsService,
    private storageService: StorageService,
    public userCommonService: UserCommonService) {

    this.router.events.subscribe((url: any) => {
      if (url.url !== undefined && url.url !== null) {
        const browserUrl = url.url.split('?');
        if (AppSettings.HEADER_NOT_REQUIRED.includes(browserUrl[0])) {
          this.showHeaderAndSidebar = false;
        } else {
          this.showHeaderAndSidebar = true;
        }
      }
    });
  }

  ngOnInit() {
    if (this.userCommonService.isUserMetadataPresent === false) {
      const idleSessionTime = +this.storageService.getItemFromCookies(AppSettings.INTERVAL);
      if (idleSessionTime) {
        this.userCommonService.getAdminUserMetaData();
        this.googleIntevalCheck = setInterval(() => {
          if (typeof google === 'object' && typeof google.maps === 'object') {
            this.initProcess();
          }
        }, 1000);
      } else {
        this.initProcess();
      }
    }

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  initProcess() {
    this.isReady = true;
  }

}
