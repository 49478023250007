import { Component, OnInit } from '@angular/core';
import { AppSettings } from './../../modules/shared/app.settings';
import { UserCommonService } from './../../modules/shared/services/user-common.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StorageService } from './../../modules/shared/services/storage.service';
import { CommonBindingDataService } from '../../modules/shared/services/common-binding-data.service';
import { NotificationsService } from './../../modules/shared/services/notifications.service';
import { Message, MessageService } from 'primeng/api';

@Component({
  selector: 'app-sign-in-layout',
  templateUrl: './sign-in-layout.component.html'
})

export class SignInLayoutComponent implements OnInit {
  signInResponse: any;
  message: Message[] = [];
  otpId;
  forgotPopup = false;
  loginErrors = [{
    email: false,
    password: false,
  }];
  subscription: Subscription;
  otpPopup = false;
  setPasswordPopup = false;
  isMetadataLoaded = false;
  otpEmail;
  otpPhone;
  data;

  signInWelcomeTextLabel;
  signInDescription;
  readMoreLabel;
  labelResetPassword;
  labelValidateOtp;
  isCompanyRole = false;

  constructor(
    private userCommonService: UserCommonService,
    private router: Router,
    private messageService: MessageService,
    private storageService: StorageService,
    private commonBindingDataService: CommonBindingDataService,
    private notificationService: NotificationsService,
  ) { }

  ngOnInit() {
    this.signInWelcomeTextLabel = this.commonBindingDataService.getLabel('label_sign_in_welcome_text');
    this.signInDescription = this.commonBindingDataService.getLabel('label_sign_in_description');
    this.readMoreLabel = this.commonBindingDataService.getLabel('label_read_more');
    this.labelResetPassword = this.commonBindingDataService.getLabel('label_reset_password');
    this.labelValidateOtp = this.commonBindingDataService.getLabel('label_validate_otp');
    const isIntervalCheck = this.storageService.getItemFromCookies(AppSettings.INTERVAL);
  }

  doSignIn(signInData) {
    this.signInApiCall(signInData);
  }

  signInApiCall(signInData) {
    this.storageService.removeAllCookies();
    this.userCommonService.signIn(JSON.stringify(signInData)).subscribe(signInResult => {
      if (signInResult) {
        this.signInResponse = signInResult;
        if (this.signInResponse.accessGroup !== null && this.signInResponse.accessGroup.length > 0) {
          this.notificationService.canFetchNotification = true;
          this.setSessionAndUserDetails(signInResult);
          this.userCommonService.getUserMasterMetaData();
          this.userCommonService.setRolesAndGenerateSidebar();
          this.router.navigateByUrl(this.userCommonService.redirectUrl);
        } else {
          this.message = [];
          this.messageService.add(
            {
              severity: 'error', summary: 'Error',
              detail: this.commonBindingDataService.getLabel('error_invalid_access_group')
            });
        }
      }
    }, (error) => {
      this.showMessage('Error', error.general[0].message);
    });
  }

  setSessionAndUserDetails(results) {
    this.storageService.setItem(AppSettings.USER_DETAILS, results.user);
    this.storageService.setItem(AppSettings.ACCESS_MENU, this.signInResponse.accessGroup);
    AppSettings.USER = results.user;

    if (results.user.userDetails !== null) {
      this.storageService.setItem(AppSettings.CURRENT_USER_CORPORATE_ID, results.user.userDetails.corporateId);
    }
  }

  moduleWiseRouteAccesses() {
    const groups = {};
    let accesses = this.signInResponse.accesses;
    for (let i = 0; i < accesses.length; i++) {
      const accessGroupName = accesses[i].accessGroupName;
      if (!groups[accessGroupName]) {
        groups[accessGroupName] = [];
      }
      groups[accessGroupName].push(accesses[i].accessName);
    }
    accesses = [];
    for (const accessGroupName in groups) {
      if (groups.hasOwnProperty(accessGroupName)) {
        accesses.push({ group: accessGroupName, accessName: groups[accessGroupName] });
      }
    }
    this.storageService.setItem(AppSettings.ACCESS_LIST, accesses);
  }

  uniqArray(arrArg) {
    return arrArg.filter((elem, pos, arr) => {
      return arr.indexOf(elem) === pos;
    });
  }

  forgotPassword() {
    this.router.navigate(['/forgot-password']);
  }

  onPopClose(event) {
    this.forgotPopup = false;
  }

  onOtpPopClose(event) {
    this.otpPopup = false;
  }

  onSetPasswordPopClose(event) {
    this.setPasswordPopup = false;
  }

  onOtpPopSave(event) {
    if (event.general !== undefined) {
      this.message = [];
      this.messageService.add({
        severity: 'error', summary: this.commonBindingDataService.getLabel('label_otp_header'),
        detail: event.general[0].message
      });
    } else {
      if (event.otpId) {
        this.otpId = event.otpId;
        this.message = [];
        this.messageService.add({
          severity: 'success', summary: this.commonBindingDataService.getLabel('verified_otp'),
          detail: this.commonBindingDataService.getLabel('matched_otp')
        });
        this.setPasswordPopup = true;
      }
    }
    this.forgotPopup = false;
    this.otpPopup = false;
  }

  onPopSave(results) {
    this.otpEmail = results;
    this.otpPhone = results.phone;
    this.message = [];
    this.message = [];
    this.messageService.add({
      severity: 'success', summary: this.commonBindingDataService.getLabel('label_forgot_password'),
      detail: results.general[0].message
    });
    this.forgotPopup = false;
    this.otpPopup = true;
  }

  showMessage(severity, message) {
    this.message = [];
    this.messageService.add({ severity: severity.toLowerCase(), summary: severity, detail: message });
  }

}
