import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AppSettings } from './../app.settings';
import { StorageService } from './../services/storage.service';
import { CommonBindingDataService } from './../services/common-binding-data.service';
import { Subject } from 'rxjs';

@Injectable()
export class SidebarService {
  public sideSubject = new Subject<any>();
  sideState = this.sideSubject.asObservable();
  menuItems: any;
  sideBarMenu = [];
  corpId;

  constructor(private commonService: CommonBindingDataService,
    private storageService: StorageService, private router: Router) {
    this.corpId = this.storageService.getItem(AppSettings.CURRENT_USER_CORPORATE_ID);
    this.generateLeftMenuBar();
  }

  setCorpAndDeptId() {
    const userDetails: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (userDetails !== undefined && userDetails !== null) {
      if (userDetails.userDetails !== undefined && userDetails.userDetails !== null) {
        if (userDetails.userDetails.corporateId !== undefined && userDetails.userDetails.corporateId !== null) {
          this.corpId = userDetails.userDetails.corporateId;
        }
        if (userDetails.userDetails.departmentId !== undefined && userDetails.userDetails.departmentId !== null) {
          AppSettings.DEPARTMENT_ID = userDetails.userDetails.departmentId;
        }
      }
    }
  }

  generateLeftMenuBar() {
    this.sideBarMenu = [];
    this.menuItems = this.storageService.getItem(AppSettings.ACCESS_MENU);

    if (this.menuItems !== undefined && this.menuItems !== null) {
      this.menuItems.forEach(menu => {
        const menuItem = this.generateMenuItem(menu);
        if (menuItem !== undefined && menuItem !== null) {
          this.sideBarMenu.push(menuItem);
        }
      });
      if (this.router.url === '/signin') {
        if (this.sideBarMenu.length > 0) {
          if (this.sideBarMenu[0].routerLink !== undefined && this.sideBarMenu[0].routerLink !== null) {
            this.router.navigate(this.sideBarMenu[0].routerLink);
            this.storageService.setItem('dashboard', this.sideBarMenu[0].routerLink);
            this.storageService.setItem('selectedMenu', this.sideBarMenu[0].accessCode);
          } else if (this.sideBarMenu[0].items !== undefined && this.sideBarMenu[0].items.length > 0) {
            this.router.navigate(this.sideBarMenu[0].items[0].routerLink);
            this.storageService.setItem('dashboard', this.sideBarMenu[0].items[0].routerLink);
            this.storageService.setItem('selectedMenu', this.sideBarMenu[0].items[0].accessCode);
          }
        }
      }
      this.commonService.redrawSidebar.next();
    }
    this.updateItems();
  }

  updateItems() {
    this.sideBarMenu = this.removeDuplicates(this.sideBarMenu, 'label');
  }

  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  generateMenuItem(menu) {
    let menuItem = null;
    if (menu !== undefined && menu !== null && menu.accessGroupCode !== 'COMMON') {
      const subMenuItems = this.generateSubmenu(menu.subMenuList);
      if (subMenuItems.length > 0) {
        menuItem = {
          label: menu.name,
          icon: menu.icon,
          items: this.generateSubmenu(menu.subMenuList)
        };
      } else {
        menuItem = {
          label: menu.name,
          icon: menu.icon,
          routerLink: [menu.displayUrl],
          accessCode: menu.accessGroupCode,
          command: () => {
            this.storageService.setItem('selectedMenu', menu.accessGroupCode);
          }
        };
      }
    }
    return menuItem;
  }

  generateSubmenu(subMenuArray) {
    const subMenus = [];
    if (subMenuArray !== undefined && subMenuArray !== null) {
      subMenuArray.forEach(menu => {
        const subMenuItems = this.generateSubmenu(menu.subMenuList);
        let menuItem = null;
        if (subMenuItems.length > 0) {
          menuItem = {
            label: menu.name,
            icon: menu.icon,
            items: this.generateSubmenu(menu.subMenuList)
          };
        } else {
          menuItem = {
            label: menu.name,
            icon: menu.icon,
            routerLink: [menu.displayUrl],
            accessCode: menu.accessGroupCode,
            command: () => {
              this.storageService.setItem('selectedMenu', menu.accessGroupCode);
            }
          };
        }
        subMenus.push(menuItem);
      });
    }
    return subMenus;
  }

}
