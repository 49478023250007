<div class="col-6 md:col-12">
  <div class="activity-box">
    <div class="grid activity-header-area">
      <h4 class="activity-header">{{headerLHS}}</h4>
    </div>
    <div class="activity-body-area">
      <div class="grid" *ngFor="let notifs of activityNotifications">
        <div class="col-11 md:col-10">
          <div class="activity-content">{{notifs.nitfication}}</div>
        </div>
        <div class="col-1 md:col-2">
          <div class="activity-time">{{notifs.time}}</div>
        </div>
      </div>
      <div class="grid">
        <span class="show-all-notifs" translate>view_all</span>
      </div>
    </div>
  </div>
</div>

<div class="col-6 md:col-12">
  <div class="activity-box">
    <div class="grid activity-header-area">
      <h4 class="activity-header">{{headerRHS}}</h4>
    </div>
    <div class="activity-body-area">
      <div class="grid" *ngFor="let notifs of activityNotifications">
        <div class="col-11 activity-content">{{notifs.nitfication}}</div>
        <div class="col-1 activity-time">{{notifs.time}}</div>
      </div>
      <div class="grid">
        <span class="show-all-notifs" translate>view_all</span>
      </div>
    </div>
  </div>
</div>