import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Message, MessageService } from 'primeng/api';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';

@Component({
  selector: 'app-booking-cancelation',
  templateUrl: './booking-cancelation.component.html'
})

export class BookingCancelationComponent implements OnInit {
  @Input() bookingID;
  @Output() isPopSave: EventEmitter<any> = new EventEmitter();
  @Output() isPopCancel: EventEmitter<any> = new EventEmitter();
  message: Message[] = [];
  packageApiError: any = [{
    pickupLocationName: false,
    cancelReason: false
  }];

  bookingCancelform = new UntypedFormGroup({
    cancelReason: new UntypedFormControl(),
  });
  labelSave;
  labelCancel;
  label_Cancelation;
  subStatusOptions = [{ value: 540, label: 'Paid Cancellation' }, { value: 550, label: 'Unpaid Cancellation' }];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private commonBindingDataService: CommonBindingDataService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.bookingCancelform = this.formBuilder.group({
      cancelReason: ['', [Validators.required]],
      tourSubStatus: ['', [Validators.required]]
    });
    this.labelSave = this.commonBindingDataService.getLabel('label_booking_cancel');
    this.labelCancel = this.commonBindingDataService.getLabel('label_cancel');
    this.label_Cancelation = this.commonBindingDataService.getLabel('label_Cancelation');
  }

  cancelBooking() {
    const data = {
      cancellationReason: this.bookingCancelform.controls.cancelReason.value,
      tourSubStatus: this.bookingCancelform.controls.tourSubStatus.value,
    };

    this.commonBindingDataService.cancelBooking(this.bookingID, data).subscribe(results => {
      this.showMessage('success', results);
      this.bookingCancelform.reset();
    }, (error) => {
      this.showMessage('Error', error);
    });
  }

  showMessage(severity, messageType) {
    this.message = [];
    this.messageService.add({ severity: severity.toLowerCase(), summary: severity, detail: messageType.general[0].message });

    setTimeout(() => {
      this.isPopSave.emit(messageType);
    }, 3000);
  }

  reset() {
    this.bookingCancelform.reset();
  }

  cancel() {
    this.isPopCancel.emit();
  }

}
