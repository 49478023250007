import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInLayoutComponent } from './pages/sign-in-layout/sign-in-layout.component';
import { AuthenticationValidationService } from './modules/shared/services/authentication-validation.service';
import { TrackMyLocationComponent } from './pages/track-my-location/track-my-location.component';
import { ForgotPasswordComponent } from './modules/shared/components/forgot-password/forgot-password.component';
import { OtpVerifiyComponent } from './modules/shared/components/otp-verifiy/otp-verifiy.component';
import { SetPasswordComponent } from './modules/shared/components/set-password/set-password.component';
import { DownloadFileComponent } from './pages/download-file/download-file.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'admin-dashboard'
  },
  {
    path: 'signin',
    pathMatch: 'full',
    component: SignInLayoutComponent
  },
  {
    path: 'forgot-password',
    pathMatch: 'full',
    component: ForgotPasswordComponent
  },
  {
    path: 'otp-verify',
    pathMatch: 'full',
    component: OtpVerifiyComponent
  },
  {
    path: 'set-password',
    pathMatch: 'full',
    component: SetPasswordComponent
  },
  {
    path: 'trackmylocation',
    pathMatch: 'full',
    component: TrackMyLocationComponent
  },
  {
    path: 'admin-dashboard',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule)
  },
  {
    path: 'trips',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/bookings/bookings.module').then(m => m.BookingsModule)
  },
  {
    path: 'emergency',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/emergency/emergency.module').then(m => m.EmergencyModule)
  },
  {
    path: 'scheduler',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/schedulers/schedulers.module').then(m => m.SchedulersModule)
  },
  {
    path: 'members',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/passengers/passengers.module').then(m => m.PassengersModule)
  },
  {
    path: 'providers',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/providers/providers.module').then(m => m.ProvidersModule)
  },
  {
    path: 'vehicles',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/vehicles/vehicles.module').then(m => m.VehiclesModule)
  },
  {
    path: 'drivers',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/drivers/drivers.module').then(m => m.DriversModule)
  },
  {
    path: 'manage-cities',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/manage-cities/manage-cities.module').then(m => m.ManageCitiesModule)
  },
  {
    path: 'manage-companies',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/manage-companies/manage-companies.module').then(m => m.ManageCompaniesModule)
  },
  {
    path: 'marketing',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/marketing/marketing.module').then(m => m.MarketingModule)
  },
  {
    path: 'app-settings',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/tenant-settings/tenant-settings.module').then(m => m.TenantSettingsModule)
  },
  {
    path: 'user-management',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/user-management/user-management.module').then(m => m.UserManagementModule)
  },
  {
    path: 'operator-management',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/operator-management/operator-management.module').then(m => m.OperatorManagementModule)
  },
  {
    path: 'settings',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'reports',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'driver-time',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/driver-time-management/driver-time-management.module').then(m => m.DriverTimeManagementModule)
  },
  {
    path: 'file',
    pathMatch: 'full',
    canActivate: [AuthenticationValidationService],
    component: DownloadFileComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
