<div class="trip-details" *ngIf="tripDetails!==null">
  <div class="grid panel-div">
    <div class="col-6 md:col-6 lg:col-6 ui-g-nopad">
      <div class="grid">
        <div class="col-12">
          <span class="info-label" translate>label_status</span>: {{tripDetails.quotationStatus }}
        </div>
        <div class="col-12">
          <div>{{ tripDetails.bookingType }}
            <span translate>label_booking</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 md:col-6 lg:col-6 ui-g-nopad">
      <div class="grid">
        <div class="col-12">
          <span class="info-label" translate>label_trip_id</span> {{tripDetails.tripId }}
        </div>
        <div class="col-12">
          <span class="info-label" translate>label_service_id</span> {{tripDetails.serviceType }}
        </div>
      </div>
    </div>

    <div [hidden]="!(tripDetails.roomNum)" class="col-12">
      <span class="info-label" translate>label_room_num</span> {{tripDetails.roomNum}}
    </div>
    <div class="col-12">
      <span class="info-label" translate>label_pickup_date_time</span> {{tripDetails.pickupDateTime|datetimeformat}}
    </div>

    <div class="col-12">
      <div class="col-6 md:col-6 lg:col-6 ui-g-nopad" *ngIf="tripDetails.vehicleType!==null">
        <span class="info-label" translate>label_vehicle_type</span> {{tripDetails.vehicleType }}
      </div>
      <div class="col-6 md:col-6 lg:col-6 ui-g-nopad" *ngIf="tripDetails.passangerCount!==null">
        <span class="info-label" translate>label_no_of_passenger</span> {{tripDetails.passangerCount }}
      </div>
    </div>

    <div class="col-12" *ngIf="tripDetails.note!=null && tripDetails.note!=undefined">
      <button type="button" (click)="showDialog()" pButton icon="fa-external-link-square" label="View Note"></button>
    </div>

    <div class="col-12">
      <span class="pickup-circle">&#9673;</span> {{tripDetails.pickupLocationName }}
    </div>
    <div class="col-12">
      <span class="drop-circle">&#9673;</span> {{tripDetails.dropLocationName }}
    </div>

    <div class="col-12" *ngIf="tripDetails.packageName!=undefined && tripDetails.packageName!=null">
      <div class="col-12 md:col-12 lg:col-12 ui-g-nopad">
        <span class="info-label" translate>label_package</span>
        <span *ngIf="tripDetails.distance!=null && tripDetails.distance >0">{{tripDetails.distance/1000 }}</span>
        <span *ngIf="tripDetails.distance!=null && tripDetails.distance>0" translate>label_km</span>
        <span *ngIf="hour!=null && hour>0">{{hour}}</span>
        <span *ngIf="hour!=null && hour>0" translate>label_hr</span>
        <span *ngIf="mins!=null && mins>0">{{mins}}</span>
        <span *ngIf="mins!=null && mins>0" translate>label_mins</span>
      </div>
    </div>

    <div class="col-12">
      <div *ngIf="tripDetails.totalAmount" class="col-12 md:col-12 lg:col-12 ui-g-nopad">
        <span class="info-label"><span translate>label_trip_amount</span>: </span>
        <span>{{tripDetails.totalAmount}}</span>
      </div>
    </div>


    <div class="col-12" *ngIf="tripDetails.uploadedQuotation!=undefined && tripDetails.uploadedQuotation!=null">
      <div class="col-6 md:col-6 lg:col-6 ui-g-nopad">
        <span class="info-label" translate>label_previous_quotation</span>
      </div>

      <div class="col-12">
        <div class="col-7 md:col-7 lg:col-7 ui-g-nopad info-label" translate>
          label_description
        </div>

        <div class="col-2 md:col-2 lg:col-2 ui-g-nopad info-label" translate>
          label_amount
        </div>
        <div class="col-3 md:col-3 lg:col-3 ui-g-nopad info-label" translate>
          label_date_time
        </div>
      </div>
      <div class="col-12" *ngFor="let quoteInfo of tripDetails.uploadedQuotation;let i = index">
        <div class="col-2 md:col-2 lg:col-2 ui-g-nopad">
          <a class="view-route-link" translate [href]="quotationBaseURL + quoteInfo.quotationFileId" download>
            <i class="fa fa-download" aria-hidden="true" title="Download"></i>
          </a>
        </div>
        <div class="col-5 md:col-5 lg:col-5 ui-g-nopad" *ngIf="quoteInfo.description!==null">
          {{quoteInfo.description}}
        </div>
        <div class="col-5 md:col-5 lg:col-5 ui-g-nopad" *ngIf="quoteInfo.description==null">
          View Quote {{i+1}}
        </div>
        <div class="col-2 md:col-2 lg:col-2 ui-g-nopad">
          {{quoteInfo.quotationAmount}}
        </div>
        <div class="col-3 md:col-3 lg:col-3 ui-g-nopad">
          {{quoteInfo.createdAt|datetimeformat}}
        </div>

      </div>
    </div>

    <div class="col-12" *ngIf="tripDetails.fareEstimate!=undefined">
      <div class="info-label" translate>label_qoutation_fare_estimate</div>
      <div>{{tripDetails.fareEstimate}}</div>
    </div>

    <div class="col-12" *ngIf="tripDetails.rejectionReason!==null && tripDetails.rejectionReason!= undefined">
      <div class="info-label" translate>label_qoutation_reason_for_cancel</div>
    </div>
    <div class="col-12" *ngIf="tripDetails.rejectionReason!==null && tripDetails.rejectionReason!= undefined">
      <div>{{tripDetails.rejectionReason}}</div>
    </div>

    <div class="col-12" *ngIf="tripDetails.cancellationReason!==null && tripDetails.cancellationReason!= undefined">
      <div class="info-label" translate>label_cancellation_reason</div>: {{tripDetails.cancellationReason}}
    </div>

    <div class="col-12" *ngIf="tripDetails.statusBtn">
      <button class="one-line-block" pButton type="button" *ngIf="!tripDetails.cancelTripBtnDisabled"
        [label]="cancelTrip" icon="fa-times" (click)="onCancelTrip()"></button>
      <button class="one-line-block" pButton type="button" *ngIf="!tripDetails.endTripBtnDisabled" [label]="endTrip"
        icon="fa-stop-circle" (click)="onEndTrip()"></button>
      <button class="one-line-block" pButton type="button" *ngIf="!tripDetails.startTripBtnDisabled" [label]="startTrip"
        icon="fa-play" (click)="onStartTrip()"></button>
      <button class="one-line-block" pButton type="button" *ngIf="!tripDetails.securityCheckBtnDisabled"
        [label]="securityCheck" icon="fa-check" (click)="onSecurityCheck()"></button>
      <button class="one-line-block" pButton type="button" *ngIf="!tripDetails.deliveryCheckBtnDisabled"
        [label]="deliveryCheck" icon="fa-check" (click)="onDeliveryCheck()"></button>
      <button class="one-line-block" pButton type="button" *ngIf="!tripDetails.carReturnCheckBtnDisabled"
        [label]="carReturnCheck" icon="fa-check" (click)="onCarReturnCheck()"></button>
      <button class="one-line-block" pButton type="button" *ngIf="!tripDetails.returnSecurityCheckBtnDisabled"
        [label]="returnSecurityCheck" icon="fa-check" (click)="onReturnSecurityCheck()"></button>
      <button class="one-line-block" pButton type="button" *ngIf="!tripDetails.extendTripBtnDisabled"
        [label]="extendTrip" icon="fa-check" (click)="onExtendTrip()"></button>
    </div>
    <div class="col-12">
      <div class="col-6 md:col-6 lg:col-6 ui-g-nopad"
        *ngIf="tripDetails.quotationStatus==='NEW_REQUEST' || tripDetails.quotationStatus==='QUOTE_REJECTED'">
        <button class="one-line-block" pButton type="button" [label]="uploadQuote" icon="fa-upload"
          (click)="onUploadQuote()"></button>

      </div>
      <div class="col-6 md:col-6 lg:col-6 ui-g-nopad"
        *ngIf="tripDetails.quotationStatus==='QUOTE_SENT' || tripDetails.quotationStatus==='RENEGOTIATED'">
        <button class="one-line-block" pButton type="button" [label]="resendQuote" icon="fa-check"
          (click)="onUploadQuote()"></button>
      </div>
      <div class="col-6 md:col-6 lg:col-6 ui-g-nopad" *ngIf="tripDetails.quotationStatus==='NEW_REQUEST' ||
       tripDetails.quotationStatus==='QUOTE_REJECTED' || tripDetails.quotationStatus==='QUOTE_SENT' 
       || tripDetails.quotationStatus==='RENEGOTIATED'">
        <button class="one-line-block" pButton type="button" [label]="cancelBooking" icon="fa-check"
          (click)="cancelSalesBooking()"></button>
      </div>
    </div>
  </div>
  <app-upload-quote (reloadGrid)="reloadGrid()"> </app-upload-quote>
  <app-cancel-sale-quotation (reloadGrid)="reloadGrid()" (closeCancelBookingView)="closeCancelBookingView()"
    [quotationId]="salesQuatationId" [cancelQuotationView]="cancelQuotationView"> </app-cancel-sale-quotation>

  <p-dialog [blockScroll]="true" header="Note" [(visible)]="display" modal="modal" [style]="{width: '300px'}" [responsive]="true">
    <p *ngIf="tripDetails.note!==null">{{tripDetails.note}}</p>
  </p-dialog>


  <p-dialog [blockScroll]="true" header="OTP Verification" [(visible)]="OTP" modal="modal" [style]="{width: '300px'}"
    [responsive]="true" [closable]="false">
    <app-otp [inputLabel]="enterOtp" (otpPopClose)="closeAllPopup($event)" (otpPopSave)="onOtpPopSave($event)"
      (otpProcess)="onStartTripProcess($event)"></app-otp>
  </p-dialog>

  <p-dialog [blockScroll]="true" header="OTP Verification" [(visible)]="securityCheckOTP" modal="modal" [style]="{width: '300px'}"
    [responsive]="true" [closable]="false">
    <app-otp [inputLabel]="enterOtp" (otpPopClose)="closeAllPopup($event)" (otpPopSave)="onOtpPopSave($event)"
      (otpProcess)="onSecurityCheckProcess($event)"></app-otp>
  </p-dialog>

  <p-dialog [blockScroll]="true" header="Delivery Check" [(visible)]="deliveryCheckStartKM" modal="modal" [style]="{width: '300px'}"
    [responsive]="true" [closable]="false">
    <app-otp [inputLabel]="currentKM" (otpPopClose)="closeAllPopup($event)" (otpPopSave)="onOtpPopSave($event)"
      (otpProcess)="onDeliveryCheckProcess($event)"></app-otp>
  </p-dialog>

  <p-dialog [blockScroll]="true" header="Car Return Check" [(visible)]="carReturnCheckEndTrip" modal="modal" [style]="{width: '300px'}"
    [responsive]="true" [closable]="false">
    <app-otp [inputRequired]="false" (otpPopClose)="closeAllPopup()" (otpPopSave)="onOtpPopSave($event)"
      (otpProcess)="onCarReturnCheckProcess($event)"></app-otp>
  </p-dialog>

  <p-dialog [blockScroll]="true" header="Return Security Check" [(visible)]="returnSecurityCheckEndKM" modal="modal"
  [style]="{width: '300px'}" [responsive]="true" [closable]="false">
    <app-otp [inputLabel]="currentKM" (otpPopClose)="closeAllPopup()" (otpPopSave)="onOtpPopSave($event)"
      (otpProcess)="onReturnSecurityCheckProcess($event)"></app-otp>
  </p-dialog>

  <p-dialog [blockScroll]="true" header="Cancel Trip" [(visible)]="displayCancelTrip" modal="modal" [style]="{width: '300px'}"
    [responsive]="true" [closable]="false">
    <div class="col-12 lg:col-12 md:col-12">
      <div class="form-field col-12 md:col-12 lg:col-12">
        <div class="form-label">
          <label for="quotationamount" translate>label_cancel_reason</label>
        </div>
        <div class="form-control">
          <input autocomplete="off" [(ngModel)]="cancelReasonText" type="text" pInputText />
        </div>
      </div>

      <div class="form-buttons col-12">
        <button pButton type="button" label="Cancel Trip" icon="fa-reply" (click)="doCancel()"></button>
        <button class="negative-button" pButton type="button" icon="fa-close" label="Cancel"
          (click)="cancel()"></button>
      </div>
    </div>
  </p-dialog>

</div>
<div class="trip-details" *ngIf="tripDetails == null">
  <div class="col-12">
    <div class="info-label" translate>booking_details_result</div>
  </div>
</div>

<p-toast></p-toast>