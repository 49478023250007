import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonBindingDataService } from './../../services/common-binding-data.service';

@Component({
  selector: 'app-comment-reply',
  templateUrl: './comment-reply.component.html'
})

export class CommentReplyComponent {
  @Input() tripCommentDetails;
  @Input() userType;
  @Output() isPopSave: EventEmitter<any> = new EventEmitter();
  @Output() isPopCancel: EventEmitter<any> = new EventEmitter();
  replyText = '';

  constructor(private commonService: CommonBindingDataService) { }

  doRply() {
    const input = {
      driverId: this.tripCommentDetails.driverId,
      passengerId: this.tripCommentDetails.passengerId,
      reply: this.replyText
    };
    (this.userType === 'driver') ? (input.passengerId = null) : (input.driverId = null);
    this.commonService.commentReply(input, this.tripCommentDetails.tripId).subscribe((results) => {
      this.replyText = '';
      this.isPopSave.emit(results);
    }, (error) => {
    });
  }

  cancel() {
    this.replyText = '';
    this.isPopCancel.emit();
  }

}
