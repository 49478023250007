<app-loader></app-loader>
<div *ngIf="isReady" class="grid device-height">
  <app-header *ngIf="notificationService.canFetchNotification" class="top-nav col-12 header-panel">
  </app-header>
  <div class="main-panel col-12">
    <app-sidebar *ngIf="notificationService.canFetchNotification" class="sidebar col-12 md:col-3 left-panel">
    </app-sidebar>

    <div class="right-panel">
      <app-page-header *ngIf="notificationService.canFetchNotification"></app-page-header>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<p-confirmDialog></p-confirmDialog>