<form [formGroup]='signInForm'>
  <div class="form-field col-12 ui-g-nopad">
    <div class="form-label">
      <label translate>label_email</label>
    </div>
    <div class="form-control">
      <input autocomplete="off" formControlName="username" class="textbox-border-bottom" type="text"
        pInputText (keypress)="eventHandler($event)" />
    </div>
    <div class="form-errors"
      *ngIf="signInForm.controls.username.errors  && (signInForm.controls.username.dirty || signInForm.controls.username.touched)">
      <div [hidden]="!signInForm.controls.username.errors.required" translate>
        email_required
      </div>
      <div [hidden]="!signInForm.controls.username.errors.maxlength" translate>
        exceed_max_length
      </div>
    </div>
  </div>

  <div class="form-field col-12 ui-g-nopad">
    <div class="form-label">
      <label translate>label_password</label>
    </div>
    <div class="form-control">
      <input autocomplete="off" appNoWhiteSpace pInputText type="{{inputType}}" formControlName="password"
        class="full-width-transperent" (keypress)="eventHandler($event)" />
      <i [ngClass]="!showPassword ? 'fa fa-eye-slash password-visible-icon' : 'fa fa-eye password-visible-icon'"
        aria-hidden="true" (click)="toggleShowPassword()"></i>
    </div>
    <div class="form-errors"
      *ngIf="signInForm.controls.password.errors  && (signInForm.controls.password.dirty || signInForm.controls.password.touched)">
      <div [hidden]="!signInForm.controls.password.errors.required" translate>
        error_password_required
      </div>
      <div [hidden]="!signInForm.controls.password.errors.minlength" translate>
        password_too_short
      </div>
      <div [hidden]="!signInForm.controls.password.errors.maxlength" translate>
        exceed_max_length
      </div>
    </div>
  </div>

  <div class="form-field col-12">
    <a (click)="onForgotPassword()" class="forgot-password-link"><label translate>forgot_password</label></a>
  </div>

  <div class="form-field col-12">
    <div class="form-field col-2 ui-g-nopad">
      <img src=''>
    </div>
    <div class="form-field col-8 ui-g-nopad">
      <button pButton label="{{signInLabel}}" class='sign-in-buttons' type="button" [disabled]='!signInForm.valid'
        (click)="doSignIn()"></button>
    </div>

  </div>
</form>

<p-toast></p-toast>