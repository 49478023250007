<div class="grid form-container">
  <form class="col-12 ui-g-nopad" [formGroup]="bookingCancelform">
    <div class="form-field">
      <div class="form-control">
        <p-dropdown [options]="subStatusOptions" placeholder="{{'label_select_sub_status' | translate}}"
          formControlName="tourSubStatus" [style]="{'width': '100%'}"></p-dropdown>
      </div>
      <div class="form-errors"
        *ngIf="bookingCancelform.controls.tourSubStatus.errors  && (bookingCancelform.controls.tourSubStatus.dirty || bookingCancelform.controls.tourSubStatus.touched)">
        <div [hidden]="!bookingCancelform.controls.tourSubStatus.errors.required" translate>
          error_select_sub_status
        </div>
      </div>
    </div>
    <div class="form-field">
      <div class="form-control">
        <textarea formControlName="cancelReason" [placeholder]="label_Cancelation" [rows]="5" [cols]="30" pInputTextarea
          autoResize="autoResize"></textarea>
      </div>
      <div class="form-errors"
        *ngIf="bookingCancelform.controls.cancelReason.errors  && (bookingCancelform.controls.cancelReason.dirty || bookingCancelform.controls.cancelReason.touched)">
        <div [hidden]="!bookingCancelform.controls.cancelReason.errors.required" translate>
          cancel_reason_req
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="form-errors alert alert-danger" [hidden]='!packageApiError[0]["pickupLocationName"]'>
        <div *ngFor="let ErrorMessage of packageApiError['pickupLocationName']">
          {{ErrorMessage.message}}
        </div>
      </div>
    </div>

    <div class="col-12 ui-g-nopad form-buttons">
      <button class="negative-button" pButton type="button" icon="fa fa-close" [label]="labelCancel"
        (click)="cancel()"></button>
      <button pButton type="button" [label]="labelSave" icon="fa fa-check" [disabled]="!bookingCancelform.valid"
        (click)="cancelBooking()"></button>
    </div>
  </form>
</div>

<p-toast></p-toast>