<div class="col-12 lg:col-12 md:col-12 assign-search-input-layout">
  <p-autoComplete class="assign search-driver" [(ngModel)]="multipleAssignments == false ? searchText : searchTextSet" 
  [suggestions]="searchTextResult" (completeMethod)="search($event)" [placeholder]="placeholder" 
  [multiple]="multipleAssignments" (onSelect)="onSelect($event)" field="name" 
  (onUnselect)="onUnselect($event)" [dropdown]="true" [size]="30">
    <ng-template let-ref pTemplate="item">
      <span class="font-18">{{ref.referrenceNumber}} - {{ref.name}}</span>
    </ng-template>
  </p-autoComplete>
</div>

<div class="form-buttons col-12">
  <button pButton type="button" label="{{positiveButtonLabel ? positiveButtonLabel : 'Assign'}}" (click)="assign()"></button>
  <button class="negative-button" pButton type="button" icon="fa-close" label="{{negativeButtonLabel ? negativeButtonLabel : 'Cancel'}}"
    (click)="cancel()"></button>
</div>