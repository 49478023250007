import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { AppSettings } from '../../modules/shared/app.settings';
import { Message, MessageService } from 'primeng/api';
import { CommonBindingDataService } from '../../modules/shared/services/common-binding-data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {
  usernamePlaceholder;
  passwordPlaceHolder;
  signInLabel;
  message: Message[] = [];
  inputType = 'password';
  showPassword = false;
  @Output() isSignInEmitter: EventEmitter<any> = new EventEmitter();
  @Output() isForgotPasswordEmitter: EventEmitter<any> = new EventEmitter();

  signInForm = new UntypedFormGroup({
    username: new UntypedFormControl(),
    password: new UntypedFormControl()
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private commonBindingDataService: CommonBindingDataService
  ) {
  }

  ngOnInit() {
    this.usernamePlaceholder = this.commonBindingDataService.getLabel('Username');
    this.passwordPlaceHolder = this.commonBindingDataService.getLabel('Password');
    this.signInLabel = this.commonBindingDataService.getLabel('label_log_in');
    this.signInForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.maxLength(50)]],
      password: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(25)]],
    });
  }

  doSignIn() {
    this.isSignInEmitter.emit(this.getLoginObject());
  }

  getLoginObject() {
    let signInData = {};
    if (this.signInForm.controls.username.errors === null) {
      signInData = {
        'email': this.signInForm.controls.username.value.trim(),
        'password': this.signInForm.controls.password.value.trim(),
        'tenantId': AppSettings.TENANT
      };
    } else {
      signInData = {
        'username': this.signInForm.controls.username.value.trim(),
        'password': this.signInForm.controls.password.value.trim(),
        'tenantId': AppSettings.TENANT
      };
    }
    return signInData;
  }

  onForgotPassword() {
    this.isForgotPasswordEmitter.emit();
  }

  eventHandler(event) {
    if (event.keyCode === 13 && this.signInForm.valid) {
      this.isSignInEmitter.emit(this.getLoginObject());
    }
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
    this.inputType = (this.showPassword) ? 'text' : 'password';
  }

  showMessage(severity, message) {
    this.message = [];
    this.messageService.add({ severity: severity.toLowerCase(), summary: severity, detail: message });
  }

}
