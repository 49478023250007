<div class="grid form-container">
  <form [formGroup]='otpForm'>
    <div class="col-12" *ngIf="inputRequired">
      <div class="form-field col-12">
        <div class="form-label">
          <label for="username">{{inputLabel}}</label>
        </div>
        <div class="form-control">
          <input autocomplete="off"  formControlName="otpInput" type="text" pInputText />
        </div>
        <div class="form-errors" *ngIf="otpForm.controls.otpInput.errors  && (otpForm.controls.otpInput.dirty || otpForm.controls.otpInput.touched)">
          <div [hidden]="!otpForm.controls.otpInput.errors.required" translate>
            invalid_otp
          </div>
        </div>
      </div>
    </div>
    <div class="form-buttons col-12 p-0">
      <button class="negative-button" pButton type="button" icon="fa fa-close" label="Cancel" (click)="closeEditForgot($event)"></button>
      <button pButton type="button" label="Submit" icon="fa fa-check" [disabled]="!otpForm.valid" (click)="OTPProcess($event)"></button>
    </div>
  </form>
</div>

<p-toast></p-toast>