
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Message, MessageService } from 'primeng/api';
import { BookingService } from './../../../bookings/services/booking.service';
import { AppSettings } from './../../../shared/app.settings';
import { ErrorBindingService } from '../../../shared/services/error-binding.service';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { TaxiBookingModel } from 'app/modules/bookings/models/taxi-booking-model';

@Component({
  selector: 'app-book-taxi',
  templateUrl: './book-taxi.component.html',
  providers: [BookingService]
})

export class BookTaxiComponent implements OnInit {
  @Input() type;
  message: Message[] = [];
  key: any[] = [];
  carCategory = [];
  hotelList = [];
  carTypeOptions = [];
  cmmOptions = [];
  apiError: any = [];

  driversResult: any[] = [{
    name: '',
    eta: '',
    referrenceNumber: ''
  }];

  searchList: any[];
  searchTextResult: any[];
  selectedItem: any;
  searchText = '';
  isCorporatePassenger = false;
  selectedPassengerCorporate;
  passengerId = '';
  passengerResult: any[] = [{
    firstName: '',
  }];

  passengerSearchList: any[];
  passengerSearchTextResult: any[];
  passengerSelectedItem: any;
  passengerSearchText = '';
  minDate: any;

  bookingTaxiForm = new UntypedFormGroup({
    pickupLocation: new UntypedFormControl(),
    dropLocation: new UntypedFormControl(),
    phoneCode: new UntypedFormControl(),
    pickupDate: new UntypedFormControl(),
    carType: new UntypedFormControl(),
    passengerName: new UntypedFormControl(),
    mobileNo: new UntypedFormControl(),
    driverAssign: new UntypedFormControl(),
    note: new UntypedFormControl(),
    VIPPassenger: new UntypedFormControl(),
    transmissionType: new UntypedFormControl(),
    email: new UntypedFormControl()
  });

  taxiBookingApiError = [{
    general: false,
  }];

  srcLat = '';
  srcLng = '';
  destiLat = '';
  destiLng = '';
  labelSave;
  labelCancel;
  label_pickup;
  label_drop;
  label_search;
  serviceIds = [];
  vehicleTypeId = [];
  latitude = '';
  longitude = '';
  urlAssign = '/secure/drivers/nearbydriver/search';
  urlPassengers = '/secure/passengers/search/list';
  assignDriversPopup = false;
  driver: any;
  driverAssignBit = false;
  pickupBit = false;
  pickupLocation = '';
  dropLocation = '';
  dateFormat;

  packageApiError = [{
    driverId: false,
    serviceTypeId: false,
    partnerId: false,
    partnerType: false,
    phoneNum: false,
    passengerName: false,
    vehicleTypeId: false,
    vehicleId: false,
    packageId: false,
    bookingStatus: false,
    sourceLattitude: false,
    sourceLongitude: false,
    destinationLatitude: false,
    destinationLongitude: false,
    pickupLocationName: false,
    dropLocationName: false,
    pickupDateTime: false,
    passangerCount: false,
    vip: false,
    vehicleAutomatic: false,
    withFuel: false,
    note: false,
    general: false,
    roomNum: false,
    quotationAmount: false,
    hotelNames: false
  }];

  constructor(
    private formBuilder: UntypedFormBuilder,
    public bookingService: BookingService,
    private errorBindingService: ErrorBindingService,
    private commonBindingDataService: CommonBindingDataService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.dateFormat = this.commonBindingDataService.getDateFormat();

    this.validation();
    if (this.type === 'hotel-bookings') {
      this.getHotels();
      this.bookingTaxiForm.addControl('roomNum',
        new UntypedFormControl('', [Validators.required,
        Validators.maxLength(9)])
      );
      this.bookingTaxiForm.addControl('hotelNames',
        new UntypedFormControl({ value: '', disabled: false }, [Validators.required])
      );
    }

    this.assignLabels();
    this.carTypeOptions.push({ label: this.commonBindingDataService.getLabel('please_select'), value: '' });
    this.getCarTypes();
    this.serviceIds.push(this.commonBindingDataService.getServiceTypeId(AppSettings.SETTING_TAXI_SERVICE_KEY));
  }

  getHotels() {
    this.hotelList = [{ label: this.commonBindingDataService.getLabel('please_select'), value: '0' }];
    this.commonBindingDataService.getHotels().subscribe(results => {
      results.forEach(element => {
        this.hotelList.push({ label: element.hotelName, value: element.hotelId });
      });
    }, (error) => {
      this.errorBindingService.onAPIValidationError(error, this.packageApiError);
    });
  }

  validation() {
    this.bookingTaxiForm = this.formBuilder.group({
      pickupLocation: ['', [Validators.required]],
      dropLocation: ['', [Validators.required]],
      pickupDate: ['', [Validators.required]],
      carType: ['', [Validators.required]],
      passengerName: ['', [
        Validators.required,
        Validators.pattern(AppSettings.PERSON_NAME_PATTERN),
        Validators.minLength(3)
      ]],
      phoneCode: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(3),
        Validators.pattern(AppSettings.PHONE_CODE)
      ]],
      mobileNo: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(20),
      ]],
      driverAssign: [''],
      note: [''],
      VIPPassenger: [],
      transmissionType: [],
      email: ['', [Validators.required, Validators.pattern(AppSettings.EMAIL_PATTERN)]],
      hotelNames: ['', [Validators.required]],
    });
  }

  getCarTypes() {
    this.commonBindingDataService.getVehicleType().subscribe(results => {
      const vehicleTypeData = results.vehicleTypeList;
      vehicleTypeData.forEach(element => {
        this.carTypeOptions.push({ label: element.vehicleType, value: element.id });
      });
    }, (error) => {
      this.errorBindingService.onAPIValidationError(error, this.packageApiError);
    });
  }

  isDriverAssigned() {
    if (this.bookingTaxiForm.controls.driverAssign.value !== undefined &&
      this.bookingTaxiForm.controls.driverAssign.value !== null &&
      this.bookingTaxiForm.controls.driverAssign.value !== '') {
      return true;
    } else {
      return false;
    }
  }

  isMobileNumberAssigned() {
    if (typeof (this.bookingTaxiForm.controls.mobileNo.value) === 'object') {
      return true;
    } else {
      return false;
    }
  }

  assignLabels() {
    this.minDate = this.commonBindingDataService.getBookLaterMinTime(
      AppSettings.SETTING_TAXI_SERVICE_KEY,
      AppSettings.TAXI_BOOK_MIN_TIME);
    this.label_search = this.commonBindingDataService.getLabel('label_search');
    this.label_pickup = this.commonBindingDataService.getLabel('label_pickup_location');
    this.label_drop = this.commonBindingDataService.getLabel('label_drop_location');
    this.labelSave = this.commonBindingDataService.getLabel('label_save');
    this.labelCancel = this.commonBindingDataService.getLabel('label_cancel');
  }

  bookingRequest() {
    let driverID;
    if (this.isDriverAssigned()) {
      driverID = this.bookingTaxiForm.controls.driverAssign.value.id;
    }

    let mobileNum = '';
    if (this.isMobileNumberAssigned()) {
      mobileNum = this.bookingTaxiForm.controls.mobileNo.value.phoneNum;
    } else {
      mobileNum = this.bookingTaxiForm.controls.mobileNo.value;
    }

    return new TaxiBookingModel({
      paymentMode: null,
      tenantId: AppSettings.TENANT,
      bookingStatus: null,
      bookLater: true,
      passengerId: this.passengerId,
      destinationLatitude: this.destiLat,
      destinationLongitude: this.destiLng,
      driverId: driverID,
      dropLocationName: this.dropLocation,
      note: this.bookingTaxiForm.controls.note.value,
      packageId: null,
      partnerId: null,
      partnerType: '',
      passengerName: this.bookingTaxiForm.controls.passengerName.value,
      numCountryCode: this.bookingTaxiForm.controls.phoneCode.value,
      phoneNum: mobileNum,
      pickupDateTime: new Date(this.bookingTaxiForm.controls.pickupDate.value).getTime(),
      pickupLocationName: this.pickupLocation,
      serviceTypeId: AppSettings.SETTING_TAXI_SERVICE,
      sourceLatitude: this.srcLat,
      sourceLongitude: this.srcLng,
      vehicleAutomatic: false,
      vehicleId: null,
      vehicleTypeId: this.bookingTaxiForm.controls.carType.value,
      vip: this.bookingTaxiForm.controls.VIPPassenger.value,
      withFuel: false,
      bookingDropPoints: [],
      email: this.bookingTaxiForm.controls.email.value,
      roomNum: this.bookingTaxiForm.controls.roomNum.value,
    });
  }

  addbooking() {
    const bookingRequest = this.bookingRequest();
    this.addHotelBooking(bookingRequest);
  }

  addHotelBooking(bookingRequest) {
    if (this.minDate > new Date(this.bookingTaxiForm.controls.pickupDate.value).getTime()) {
      const error = {
        general: [
          {
            message: this.commonBindingDataService.getLabel('error_invalid_date')
          }
        ]
      };
      this.errorBindingService.onAPIValidationError(JSON.stringify(error), this.packageApiError);
    } else {
      const hotelId = this.bookingTaxiForm.controls.hotelNames.value;
      this.commonBindingDataService.addHotelBooking(bookingRequest, AppSettings.SETTING_TAXI_SERVICE, hotelId).subscribe(results => {
        this.successSave(results);

      }, (error) => {
        this.errorBindingService.onAPIValidationError(error, this.packageApiError);
      });
    }
  }

  successSave(results, msg?) {
    if (msg) { } else {
      msg = 'success';
    }
    this.message = [];
    this.messageService.add({ severity: msg, summary: msg, detail: results.general[0].message });
    this.bookingTaxiForm.reset();
  }

  cancel() {
    this.bookingTaxiForm.reset();
  }

  pickupDateTimeSelected(event) {
    const selectedTime = event.getTime();
    const currentTime = new Date().getTime();
    if (currentTime > selectedTime) {
      const error = {
        pickupDate: [
          {
            message: this.commonBindingDataService.getLabel('pickup_time_validation')
          }
        ]
      };
      this.errorBindingService.onAPIValidationError(JSON.stringify(error), this.packageApiError);
    }
  }

  DriverAssignFocus() {
    if (this.isPickUpLocationMissing()) {
      this.onPickuplocationMissing();
    }
  }

  isPickUpLocationMissing() {
    if ((this.srcLat && this.srcLng) === '') {
      return true;
    } else {
      return false;
    }
  }

  onPickuplocationMissing() {
    const error = {
      pickupLocationName: [
        {
          message: this.commonBindingDataService.getLabel('pickup_location_validation_msg')
        }
      ]
    };
    this.errorBindingService.onAPIValidationError(JSON.stringify(error), this.packageApiError);
  }

  search(event) {
    if (this.isPickUpLocationMissing()) {
      this.onPickuplocationMissing();
    } else {
      const searchText = event.query;
      this.commonBindingDataService.searchDriver(this.getSearchUrl(searchText))
        .subscribe(data => {
          if (data != null) {
            this.searchList = data;
            const c = this;
            this.searchTextResult = [];
            this.searchList.forEach(e => {
              c.searchTextResult.push({
                name: e.firstName + ' ' + e.lastName,
                id: e.driverId,
                referrenceNumber: e.referrenceNumber,
                eta: e.eta,
                corporateId: e.corporateId
              });
            });
          }
        });
    }
  }

  passengerSearch(event) {
    const searchText = event.query;
    this.commonBindingDataService.searchPassengers(this.getPassengersSearchUrl(searchText))
      .subscribe(data => {
        if (data != null) {
          this.passengerSearchList = data;
          const c = this;
          this.passengerSearchTextResult = [];
          this.passengerSearchList.forEach(e => {
            e.phoneNum = parseInt(e.phoneNum, 10);
            c.passengerSearchTextResult.push({
              phoneNum: parseInt(e.phoneNum, 10),
              email: e.email,
              numCountryCode: e.numCountryCode,
              name: e.firstName + ' ' + this.checkNull(e.lastName),
              corporateId: e.corporateId,
              corporateName: e.corporateName,
              id: e.id
            });
          });
        }
      });
  }

  checkNull(obj) {
    if (obj !== null) {
      return obj;
    } else {
      return '';
    }
  }

  getPassengersSearchUrl(searchText) {
    let url;
    if (this.urlPassengers !== null && this.urlPassengers !== '') {
      url = `${this.urlPassengers}?searchText=${searchText}`;
    }
    return url;
  }

  getSearchUrl(searchText) {
    let url;
    if (this.urlAssign !== null && this.urlAssign !== '') {
      url = `${this.urlAssign}?serviceTypeId=${this.serviceIds && this.serviceIds.toString() || ''}&` +
        `searchText=${searchText}&vehicleTypeId=${this.vehicleTypeId && this.vehicleTypeId.toString() || ''}` +
        `&latitude=${this.latitude}&longitude=${this.longitude}`;
    }
    return url;
  }

  setPickup(place) {
    const carType = this.bookingTaxiForm.controls.carType.value;
    this.srcLat = place.lat;
    this.srcLng = place.lng;
    this.latitude = place.lat;
    this.longitude = place.lng;
    this.pickupLocation = place.address;
  }

  setDropUp(place) {
    this.destiLat = place.lat;
    this.destiLng = place.lng;
    this.dropLocation = place.address;
  }

  changeCarType(event) {
    this.vehicleTypeId = [];
    if (event.value !== '') {
      this.driverAssignBit = true;
    } else {
      this.driverAssignBit = false;
    }
    this.vehicleTypeId.push(event.value);
  }

  onSelectMobileNumber(event) {
    this.bookingTaxiForm.controls.passengerName.setValue(event.name);
    this.bookingTaxiForm.controls.email.setValue(event.email);
    this.bookingTaxiForm.controls.phoneCode.setValue(event.numCountryCode);
    this.selectedPassengerCorporate = event.corporateName;
    if (event.corporateId !== null) {
      this.isCorporatePassenger = true;
      this.passengerId = event.id;
    } else {
      this.isCorporatePassenger = false;
      this.passengerId = '';
    }
  }

}
