import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { Message, MessageService } from 'primeng/api';
import { AppSettings } from '../../../shared/app.settings';
import { StorageService } from './../../services/storage.service';
import { UiService } from './../../services/ui.service';
import { UserCommonService } from './../../services/user-common.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  providers: [MessageService]
})

export class EditProfileComponent implements OnInit {
  @Input() editProfilePopup: boolean;
  @Output() showProfilePopup: EventEmitter<any> = new EventEmitter();
  editProfileForm = new UntypedFormGroup({
    firstName: new UntypedFormControl(),
    lastName: new UntypedFormControl(),
    email: new UntypedFormControl(),
    username: new UntypedFormControl(),
    phone: new UntypedFormControl(),
    phoneCode: new UntypedFormControl()
  });
  countryCodeList = AppSettings.COUNTRY_CODE;
  message: Message[] = [];
  profileUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
  fileUploadUrl: string = AppSettings.UPLOAD_FILE_URL;
  uploadedImgId = '';
  chooseLabel;
  first_name;
  last_name;
  email_id;
  phone_no;
  edit_profile;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private storageService: StorageService,
    private commonServices: UserCommonService,
    private uiService: UiService,
    private messageService: MessageService,
    private commonBindingDataService: CommonBindingDataService,
  ) { }

  ngOnInit() {
    this.chooseLabel = this.commonBindingDataService.getLabel('label_upload');
    this.editProfileForm = this.formBuilder.group({
      firstName: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
        Validators.pattern(AppSettings.NAME_PATTERN)
      ]],
      lastName: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
        Validators.pattern(AppSettings.NAME_PATTERN)
      ]],
      email: ['', [
        Validators.required,
        Validators.pattern(AppSettings.EMAIL_PATTERN)
      ]],
      phone: ['', [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(13),
        Validators.pattern(AppSettings.PHONE_PATTERN)
      ]],
      phoneCode: ['', [Validators.required]]
    });

    this.edit_profile = this.commonBindingDataService.getLabel('label_edit_profile');
    this.first_name = this.commonBindingDataService.getLabel('label_first_name');
    this.last_name = this.commonBindingDataService.getLabel('label_last_name');
    this.email_id = this.commonBindingDataService.getLabel('label_email_imp');
    this.phone_no = this.commonBindingDataService.getLabel('label_phone_num');
  }

  setData() {
    this.countryCodeList = AppSettings.COUNTRY_CODE;
    const userObj: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (userObj !== undefined && userObj !== null) {
      this.editProfileForm.controls.firstName.setValue(userObj.firstName);
      this.editProfileForm.controls.lastName.setValue(userObj.lastName);
      this.editProfileForm.controls.email.setValue(userObj.email);
      this.editProfileForm.controls.phone.setValue(userObj.phoneNum);
      this.editProfileForm.controls.phoneCode.setValue(userObj.numCountryCode);

      if (userObj.userDetails !== undefined && userObj.userDetails !== null && userObj.userDetails.profileImage !== null) {
        this.profileUrl = AppSettings.GET_FILE_THUMB_URL + userObj.userDetails.profileImage;
      }
    }
  }

  closeEditProfile(event: any) {
    this.showProfilePopup.emit(false);
  }

  onUploadProfileImage(response) {
    this.profileUrl = AppSettings.GET_FILE_THUMB_URL + response.fileId;
    this.uploadedImgId = response.fileId;
  }

  updateProfile(event: any) {
    const editProfileData = {
      firstName: this.editProfileForm.controls.firstName.value,
      lastName: this.editProfileForm.controls.lastName.value,
      email: this.editProfileForm.controls.email.value,
      phoneNum: this.editProfileForm.controls.phone.value,
      profileImage: this.uploadedImgId,
      numCountryCode: this.editProfileForm.controls.phoneCode.value,
    };

    this.commonServices.updateUserProfile(editProfileData).subscribe(results => {
      if (results.user !== null && results.user !== undefined) {
        this.storageService.setItem(AppSettings.USER_DETAILS, results.user);
        this.messageService.add({severity: 'success', summary: 'Success', detail: this.commonBindingDataService.getLabel('label_profile_updated_successfully') });
        setTimeout(() => {
          this.showProfilePopup.emit(false);
          this.uiService.profileUpdated();
        }, 1000);
      }
    }, (error) => {
      this.messageService.add({severity: 'error', summary: 'Error', detail: error.general[0].message });
    });
  }
  
  showMessage(severity, message) {
    // this.showMessage('Success', this.commonBindingDataService.getLabel('label_profile_updated_successfully'));
    // this.showMessage('Error', error.general[0].message);
    this.message = [];
    this.messageService.add({key:'tst', severity: severity.toLowerCase(), summary: severity, detail: message });
  }

}
