<div class="comment-info" *ngIf="tripCommentDetails!==null">
  <div class="col-12">
    <div class="info-label" translate>comment_heading</div>
  </div>
  <div class="grid panel-div">
    <div class="col-12 p-0">
      <div class="grid" *ngIf="tripCommentDetails.passengerComment != null">
        <div class="col-12">
          <span translate>label_passenger </span><span translate>label_rating_comments </span>
        </div>
        <div class="col-12 p-0">
          <div class="col-2" *ngIf="tripCommentDetails.driverRating != null">
            <div class="image-code image-rating-width"
              [ngClass]="{'1':'rating1','2':'rating2','3':'rating3','4':'rating4','5':'rating5'}[tripCommentDetails.driverRating]">
            </div>
          </div>
          <div class="col-8" *ngIf="tripCommentDetails.passengerComment != null">
            <span>{{ tripCommentDetails.passengerComment }} </span>
          </div>
          <div class="col-2">
          </div>
          <div class="col-12 p-0" *ngFor="let item of tripCommentDetails.passengerCommentReply">
            <div class="col-2">
              <span>{{ item.repliedBy}}:</span>
            </div>
            <div class="col-8">
              <span>{{ item.reply }} </span>
            </div>

            <div class="col-2">
              <span>{{ item.createdAt | ago}} ago</span>
            </div>
          </div>
        </div>
        <div class="grid" *ngIf="tripCommentDetails.driverComment != null">
          <div class="col-12 p-0">
            <span translate>label_driver </span><span translate>label_rating_comments </span>
          </div>
          <div class="col-12">
            <div class="col-2" *ngIf="tripCommentDetails.driverRating != null">
              <div class="image-code image-rating-width"
                [ngClass]="{'1':'rating1','2':'rating2','3':'rating3','4':'rating4','5':'rating5'}[tripCommentDetails.passengerRating]">
              </div>
            </div>
            <div class="col-8" *ngIf="tripCommentDetails.driverComment != null">
              <span>{{ tripCommentDetails.driverComment }} </span>
            </div>
          </div>

          <div class="col-12 p-0" *ngFor="let item of tripCommentDetails.driverCommentReply">
            <div class="col-2">
              <span *ngIf="item.repliedBy!=null">{{ item.repliedBy}}:</span>
              <span *ngIf="item.repliedBy===null">Admin :</span>
            </div>
            <div class="col-8">
              <span>{{ item.reply }} </span>
            </div>

            <div class="col-2">
              <span>{{ item.createdAt | ago}} ago</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="comment-info" *ngIf="tripCommentDetails === null">
  <div class="col-12">
    <div class="info-label" translate>comment_heading</div>
  </div>
  <div class="col-12">
    <div class="info-label" translate>booking_comment_details</div>
  </div>
</div>

<p-dialog [blockScroll]="true" header="Reply Comment" [(visible)]="display" modal="modal" [style]="{width: '300px'}"
  [responsive]="true" [closable]="false">
  <app-comment-reply [tripCommentDetails]="tripCommentDetails" [userType]="userType" (isPopCancel)="isPopCancel($event)"
    (isPopSave)="isPopSave($event)"></app-comment-reply>
</p-dialog>

<p-toast></p-toast>