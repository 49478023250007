<div class="signin-box-container grid">
  <div class="signin-box-inner-container">
    <div class="signin-box md:col-4 col-offset-4">
      <div class="forgot-pass-form">
        <div class="form-field col-12 ui-g-nopad">
          <div class="form-field col-12 ui-g-nopad text-center">
            <img src='./assets/images/temp/logo-nemp.png'>
          </div>
          <div class="form-field col-12 switch-btn" translate>label_verify_email</div>
          <div class="form-field col-12"></div>
        </div>
        <div class="form-field col-12 md:col-12 lg:col-12">
          <form [formGroup]='editforgotForm'>
            <div class="form-field col-12 md:col-12 lg:col-12">
              <div class="form-label">
                <label for="username" translate>Username</label>
              </div>
              <div class="form-control">
                <input autocomplete="off"  formControlName="username" class="bottom-border" type="text"
                  pInputText />
              </div>
              <div class="form-errors"
                *ngIf="editforgotForm.controls.username.errors  && (editforgotForm.controls.username.dirty || editforgotForm.controls.username.touched)">
                <div [hidden]="!editforgotForm.controls.username.errors.required" translate>
                  email_required
                </div>
                <div [hidden]="!editforgotForm.controls.username.errors.pattern" translate>
                  error_invalid_email
                </div>
              </div>
            </div>

            <div class="form-field col-12">
              <div class="form-buttons col-12 ui-g-nopad">
                <button pButton type="button" class="signin-save-btn" label="Send" [disabled]="!editforgotForm.valid"
                  (click)="changePassword($event)"></button>
                <button pButton class="cancel-back-btn " type="button" label="Cancel"
                  (click)="onBackSign($event)"></button>
              </div>
            </div>
          </form>
        </div>

        <div class="remove-float"></div>
      </div>

    </div>
  </div>
</div>

<p-toast></p-toast>