import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Message } from 'primeng/api';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html'
})

export class OtpComponent implements OnInit {
  @Output() otpProcess: EventEmitter<any> = new EventEmitter();
  @Output() otpPopClose: EventEmitter<any> = new EventEmitter();
  @Output() otpPopSave: EventEmitter<any> = new EventEmitter();
  @Input() inputRequired = true;
  @Input() inputLabel;
  msg: Message[] = [];
  otpForm = new UntypedFormGroup({
    otpInput: new UntypedFormControl()
  });
  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.otpForm = this.formBuilder.group({
      otpInput: ['', [Validators.required]],
    });

    if (!this.inputRequired) {
      this.otpForm.controls.otpInput.setValue('notRequired');
    }
  }

  dispalyMessage(msgObj, msgType) {
    this.msg = [];
    this.msg.push({
      severity: msgType,
      summary: 'Error',
      detail: msgObj.general[0].message
    });
  }

  closeEditForgot(event) {
    if (!this.inputRequired) {
      this.otpForm.controls.otpInput.setValue('notRequired');
    } else {
      this.otpForm.reset();
    }
    this.otpPopClose.emit();
  }

  OTPProcess(event) {
    const otpInput = this.otpForm.controls.otpInput.value;
    this.otpForm.reset();
    this.otpProcess.emit(otpInput);
  }

}
