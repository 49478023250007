import { StorageService } from './../../shared/services/storage.service';
import { Injectable } from '@angular/core';
import { RestApiService } from '../../shared/services/rest-api.service';
import { AppSettings } from '../../shared/app.settings';
import { Observable } from 'rxjs';

@Injectable()
export class BookingService {
  bookingDetail: any = null;
  obj: any;
  selectedService: any = '';
  statusOptions: any[] = [];
  selectedStatus: any = '';
  params: any = '';

  packageApiError = [{
    driverId: false,
    serviceTypeId: false,
    partnerId: false,
    partnerType: false,
    phoneNum: false,
    passengerName: false,
    vehicleTypeId: false,
    vehicleId: false,
    packageId: false,
    bookingStatus: false,
    sourceLattitude: false,
    sourceLongitude: false,
    destinationLatitude: false,
    destinationLongitude: false,
    pickupLocationName: false,
    dropLocationName: false,
    pickupDateTime: false,
    passangerCount: false,
    vip: false,
    vehicleAutomatic: false,
    withFuel: false,
    note: false,
    general: false,
    makeModel: false,
  }];

  constructor(
    private restApiService: RestApiService,
    private storageService: StorageService
  ) { }

  addUpdateBooking(data, serviceType, id?): Observable<any> {
    return this.restApiService.post('', `/secure/booking/${serviceType}`, data);
  }

  addChauffeurBooking(data, serviceType): Observable<any> {
    return this.restApiService.post('', `/secure/booking/${serviceType}/chauffeur`, data);
  }


  addUpdateRentalBooking(data, serviceType): Observable<any> {
    return this.restApiService.post('', `/secure/booking/${serviceType}/rental`, data);
  }

  packageBind(serviceType, passengerId?, vehicleTypeId?): Observable<any> {
    if (vehicleTypeId !== null && vehicleTypeId !== undefined) {
      const url = '/secure/packages/allpackages/'
        + this.storageService.getItem(AppSettings.SERVICE_AREA_ID) + '/' + serviceType +
        '?vehicleTypeId=' + vehicleTypeId + '&passengerId' + passengerId;
      return this.restApiService.get('', url, 'page-center');
    } else {
      const url = '/secure/packages/allpackages/'
        + this.storageService.getItem(AppSettings.SERVICE_AREA_ID) + '/' + serviceType;
      return this.restApiService.get('', url, 'page-center');
    }
  }

  searchDriver(url: string): Observable<any> {
    return this.restApiService.get('', url, 'page-center');
  }

  searchPassengers(url: string): Observable<any> {
    return this.restApiService.get('', url, 'page-center');
  }

  assigningDriver(bookingId, data): Observable<any> {
    return this.restApiService.put('', '/secure/booking/' + bookingId + '/assign-driver', data);
  }

  assignDriver(bookingId, driverId, data): Observable<any> {
    return this.restApiService.put('', '/secure/tour/assign-driver/' + bookingId + '/' + driverId, data);
  }

  reassignDriver(bookingId, driverId, data): Observable<any> {
    return this.restApiService.put('', '/secure/tour/reassign-driver/' + bookingId + '/' + driverId, data);
  }

  cancelBooking(id, data): Observable<any> {
    return this.restApiService.put('', '/secure/booking/' + id + '/cancel', data);
  }

  bookingDetails(id): Observable<any> {
    return this.restApiService.get('', '/secure/booking/' + id, 'page-center');
  }

  fetchTourBooking(tourId): Observable<any> {
    return this.restApiService.get('', '/secure/tour/' + tourId, 'page-center');
  }

  startTrip(data, tripid): Observable<any> {
    return this.restApiService.put('', '/secure/booking/trip/' + tripid + '/start-trip', data);
  }

  endTrip(data, tripid): Observable<any> {
    return this.restApiService.put('', '/secure/booking/trip/' + tripid + '/end-trip', data);
  }

  cancelTrip(data, tripid): Observable<any> {
    return this.restApiService.put('', '/secure/booking/trip/' + tripid + '/cancel-trip', data);
  }

  resendCode(bookingId, passengerId): Observable<any> {
    return this.restApiService.put('', '/secure/booking/' + bookingId + '/verification-code');
  }

  getCities(): Observable<any> {
    return this.restApiService.get('', '/secure/service-area/cities', 'page-center');
  }

  getRegionByCityId(cityId): Observable<any> {
    return this.restApiService.get('', `/secure/service-area/regions/${cityId}`, 'page-center');
  }

  sendEmailInvoice(tourId, data): Observable<any> {
    return this.restApiService.post('', `/secure/tour-receipt/email/` + tourId, data, 'page-center');
  }

  refundBookingAmount(tourId, data): Observable<any> {
    return this.restApiService.post('', `/secure/tour/refund/` + tourId, data, 'page-center');
  }

  getPassengersList(searchString?): Observable<any> {
    return this.restApiService.get('', '/secure/passengers/search/list?searchText=' + searchString, 'page-center');
  }

  getRoleList(): Observable<any> {
    return this.restApiService.get('', '/secure/tour/filter/roles', 'page-center');
  }

  getProviderList(searchString?): Observable<any> {
    return this.restApiService.get('', '/secure/provider/list/data?searchText=' + searchString, 'page-center');
  }

  getDriversList(srcLat, srcLng, destLat, DestLng, numOfSeats, rideTypeId, serviceTypeId,
    vehicleTypeId, searchString?, pickupDateTime?, limit?, offset?): Observable<any> {
    return this.restApiService.get('', '/secure/admin-booking/driver-list?offset=' + offset + '&limit=' + limit +
      '&pickupLat=' + srcLat + '&pickupLong=' + srcLng + '&destLat=' + destLat + '&destLong=' + DestLng + '&numOfSeats=' +
      numOfSeats + '&vehicleTypeId=' + vehicleTypeId + '&searchString=' + searchString
      + '&pickupDateTime=' + pickupDateTime + '&rideTypeId=' + rideTypeId + '&serviceTypeId=' + serviceTypeId);
  }

  getAssignDriversList(tourId, searchText, globalTimeGroupId, limit, offset, reassign): Observable<any> {
    return this.restApiService.get('', '/secure/admin-booking/driver-list-schedule/' + tourId + '?offset=' + offset + '&limit=' + limit +
      '&searchText=' + searchText + '&globalTimeGroupId=' + globalTimeGroupId + '&reassignDriver=' + reassign);
  }

  getReassignDriversList(tripId, srcLat, srcLng, destLat, DestLng, numOfSeats, rideTypeId, serviceTypeId,
    vehicleTypeId, searchString?, pickupDateTime?, limit?, offset?): Observable<any> {
    return this.restApiService.get('', '/secure/admin-booking/reassign/driver-list/' + tripId + '?offset=' + offset + '&limit=' + limit +
      '&pickupLat=' + srcLat + '&pickupLong=' + srcLng + '&destLat=' + destLat + '&destLong=' + DestLng + '&numOfSeats=' +
      numOfSeats + '&vehicleTypeId=' + vehicleTypeId + '&searchString=' + searchString
      + '&pickupDateTime=' + pickupDateTime + '&rideTypeId=' + rideTypeId + '&serviceTypeId=' + serviceTypeId);
  }

  getPreBookingDetails(): Observable<any> {
    return this.restApiService.get('', `/secure/admin-booking/pre-booking/data`, 'page-center');
  }

  getReasons(type): Observable<any> {
    return this.restApiService.get('', `/secure/reasons?type=${type}`, 'page-center');
  }

  getReasonMapping(): Observable<any> {
    return this.restApiService.get('', `/secure/appointmenttype-resoan/mapping-list`, 'page-center');
  }

  getFareEstimate(servicetypeid, data): Observable<any> {
    return this.restApiService.post('', `/secure/booking/${servicetypeid}/fare-estimation`, data, 'page-center');
  }

  updateFareEstimate(servicetypeid, data, estimateId): Observable<any> {
    return this.restApiService.put('', `/secure/booking/${servicetypeid}/fare-estimation/${estimateId}`, data, 'page-center');
  }

  getFareEstimateForRideShare(data): Observable<any> {
    return this.restApiService.post('', `/secure/ride-share/fare-estimate`, data, 'page-center');
  }

  bookRideNowAndRideLater(servicetypeid, data): Observable<any> {
    return this.restApiService.post('', `/secure/booking/manual/${servicetypeid}`, data, 'page-center');
  }

  updateBookRideNowAndRideLater(servicetypeid, data, tripId): Observable<any> {
    return this.restApiService.put('', `/secure/tour/update/${servicetypeid}/${tripId}`, data, 'page-center');
  }

  bookRideShare(data): Observable<any> {
    return this.restApiService.post('', `/secure/ride-share/booking/manual`, data, 'page-center');
  }

  getNearByVehicles(sLatitude, sLongitude, dLatitude, dLongitude, radius, serviceTypeId, vehicleTypeId): Observable<any> {
    return this.restApiService.get('', `/secure/vehicles/data/nearbyvehicles/eta?sLatitude=
    ${sLatitude}&sLongitude=${sLongitude}&dLatitude=${dLatitude}&dLongitude=${dLongitude}&radius=
    ${radius}&serviceTypeId=${serviceTypeId}&vehicleTypeId=${vehicleTypeId}`, 'page-center');
  }


  getNearByVehiclesForRideShare(sLatitude, sLongitude, dLatitude, dLongitude, serviceTypeId): Observable<any> {
    return this.restApiService.get('', `/secure/ride-share/near-by-vehicles/eta?slat=${sLatitude}&slong=
    ${sLongitude}&dlat=${dLatitude}&dlong=${dLongitude}&serviceTypeId=${serviceTypeId}`, 'page-center');
  }

  getUpdatedLocation(bookingId?): Observable<any> {
    return this.restApiService.get('', '/secure/track-my-location/live/driver-location/' + bookingId, 'page-center');
  }

  getEstimateTimeDistance(data): Observable<any> {
    return this.restApiService.post('', `/secure/tour/estimate/timedistance`, data, 'page-center');
  }

  updatesubStatus(data): Observable<any> {
    return this.restApiService.put('', `/secure/tour/sub-status`, data, 'page-center');
  }
}
