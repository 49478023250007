<div class="grid form-container">
  <form [formGroup]="editTaxiForm" class="col-12">
    <div class=" grid">
      <div class="form-field col-12 md:col-6 lg:col-6">
        <div class="form-label grid">
          <label for="rate" translate>label_base_fare</label>
          <label class="asterisk">*</label>
        </div>
        <div class="grid">
          <input autocomplete="off" pInputText type="text" formControlName="base_rate"
            (focus)="settingApiError[0]['baseRate']=false" />
        </div>
        <div class="form-errors alert alert-danger" [hidden]='!settingApiError[0]["baseRate"]'>
          <div *ngFor="let ErrorMessage of settingApiError['baseRate']">
            {{ErrorMessage.message}}
          </div>
        </div>
        <div class="form-errors"
          *ngIf="editTaxiForm.controls.base_rate.errors  && (editTaxiForm.controls.base_rate.dirty || editTaxiForm.controls.base_rate.touched)">
          <div [hidden]="!editTaxiForm.controls.base_rate.errors.required" translate>
            route_fare
          </div>
          <div [hidden]="!editTaxiForm.controls.base_rate.errors.pattern" translate>
            invalid
          </div>
        </div>
      </div>

      <div class="form-field col-12 md:col-6 lg:col-6">
        <div class="form-label grid">
          <label for="rate" translate>label_distance_for_base_fare</label>
          <label class="asterisk">*</label>
        </div>
        <div class="grid">
          <input autocomplete="off" pInputText type="text" [appDisableControl]="true" formControlName="distance"
            (focus)="settingApiError[0]['distance']=false" />
        </div>
        <div class="form-errors alert alert-danger" [hidden]='!settingApiError[0]["distance"]'>
          <div *ngFor="let ErrorMessage of settingApiError['distance']">
            {{ErrorMessage.message}}
          </div>
        </div>
        <div class="form-errors"
          *ngIf="editTaxiForm.controls.distance.errors  && (editTaxiForm.controls.distance.dirty || editTaxiForm.controls.distance.touched)">
          <div [hidden]="!editTaxiForm.controls.distance.errors.required" translate>
            distance
          </div>
          <div [hidden]="!editTaxiForm.controls.distance.errors.pattern" translate>
            distance_invalid
          </div>
        </div>
      </div>

      <div class="form-field col-12 md:col-6 lg:col-6">
        <div class="form-label grid">
          <label for="rate" translate>label_distance_fare</label>
          <label class="asterisk">*</label>
        </div>
        <div class="grid">
          <input autocomplete="off" pInputText type="text" formControlName="distance_rate"
            (focus)="settingApiError[0]['additionalDistanceRate']=false" />
        </div>
        <div class="form-errors alert alert-danger" [hidden]='!settingApiError[0]["additionalDistanceRate"]'>
          <div *ngFor="let ErrorMessage of settingApiError['distance_rate']">
            {{ErrorMessage.message}}
          </div>
        </div>
        <div class="form-errors"
          *ngIf="editTaxiForm.controls.distance_rate.errors  && (editTaxiForm.controls.distance_rate.dirty || editTaxiForm.controls.distance_rate.touched)">
          <div [hidden]="!editTaxiForm.controls.distance_rate.errors.required" translate>
            route_fare
          </div>
          <div [hidden]="!editTaxiForm.controls.distance_rate.errors.pattern" translate>
            invalid
          </div>
        </div>
      </div>

      <div class="form-field col-12 md:col-6 lg:col-6">
        <div class="form-label grid">
          <label for="rate" translate>label_wait_time_fare</label>
          <label class="asterisk">*</label>
        </div>
        <div class="grid">
          <input autocomplete="off" pInputText type="text" formControlName="wait_rate"
            (focus)="settingApiError[0]['waitTimeFare']=false" />
        </div>
        <div class="form-errors alert alert-danger" [hidden]='!settingApiError[0]["waitTimeFare"]'>
          <div *ngFor="let ErrorMessage of settingApiError['waitTimeFare']">
            {{ErrorMessage.message}}
          </div>
        </div>
        <div class="form-errors"
          *ngIf="editTaxiForm.controls.wait_rate.errors  && (editTaxiForm.controls.wait_rate.dirty || editTaxiForm.controls.wait_rate.touched)">
          <div [hidden]="!editTaxiForm.controls.wait_rate.errors.required" translate>
            route_fare
          </div>
          <div [hidden]="!editTaxiForm.controls.wait_rate.errors.pattern" translate>
            invalid
          </div>
        </div>
      </div>
      <div class="form-field col-12 md:col-6 lg:col-6">
        <div class="form-label grid">
          <label for="rate" translate>label_ride_time_fare</label>
          <label class="asterisk">*</label>
        </div>
        <div class="grid">
          <input autocomplete="off" pInputText type="text" formControlName="ride_rate"
            (focus)="settingApiError[0]['rideTimeFare']=false" />
        </div>
        <div class="form-errors alert alert-danger" [hidden]='!settingApiError[0]["rideTimeFare"]'>
          <div *ngFor="let ErrorMessage of settingApiError['rideTimeFare']">
            {{ErrorMessage.message}}
          </div>
        </div>
        <div class="form-errors"
          *ngIf="editTaxiForm.controls.ride_rate.errors  && (editTaxiForm.controls.ride_rate.dirty || editTaxiForm.controls.ride_rate.touched)">
          <div [hidden]="!editTaxiForm.controls.ride_rate.errors.required" translate>
            route_fare
          </div>
          <div [hidden]="!editTaxiForm.controls.ride_rate.errors.pattern" translate>
            invalid
          </div>
        </div>
      </div>
      <div class="form-field col-12 md:col-6 lg:col-6">
        <div class="form-label grid">
          <label for="rate" translate>label_cancellation_fee</label>
          <label class="asterisk">*</label>
        </div>
        <div class="grid">
          <input autocomplete="off" pInputText type="text" formControlName="cancellation_rate"
            (focus)="settingApiError[0]['cancellationRate']=false" />
        </div>
        <div class="form-errors alert alert-danger" [hidden]='!settingApiError[0]["cancellationRate"]'>
          <div *ngFor="let ErrorMessage of settingApiError['cancellationRate']">
            {{ErrorMessage.message}}
          </div>
        </div>
        <div class="form-errors"
          *ngIf="editTaxiForm.controls.cancellation_rate.errors  && (editTaxiForm.controls.cancellation_rate.dirty || editTaxiForm.controls.cancellation_rate.touched)">
          <div [hidden]="!editTaxiForm.controls.cancellation_rate.errors.required" translate>
            route_fare
          </div>
          <div [hidden]="!editTaxiForm.controls.cancellation_rate.errors.pattern" translate>
            invalid
          </div>
        </div>
      </div>
    </div>

    <div class="grid">
      <div class="form-field col-12 md:col-12 lg:col-12">
        <div class="form-errors alert alert-danger" [hidden]='!settingApiError[0]["general"]'>
          <div *ngFor="let ErrorMessage of settingApiError['general']">
            {{ErrorMessage.message}}
          </div>
        </div>
      </div>
    </div>

    <div class="form-buttonscol-12 p-0">
      <button pButton type="button" [label]="Save" icon="fa-check" [disabled]="!editTaxiForm.valid"
        (click)="editRoutePackages()"></button>
      <button class="negative-button" pButton type="button" icon="fa-close" [label]="Cancel"
        (click)="cancel()"></button>
    </div>
  </form>
</div>