
import * as moment from 'moment/moment';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { AppSettings } from './../../app.settings';
import { UiService } from '../../services/ui.service';
import { MenuItem, Message, MessageService } from 'primeng/api';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { StorageService } from './../../services/storage.service';
import { UserCommonService } from './../../services/user-common.service';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { NotificationsService } from './../../services/notifications.service';
import { EditProfileComponent } from './../edit-profile/edit-profile.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'

})

export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild(EditProfileComponent, { static: false }) editPro: EditProfileComponent;
  notificationInterval;
  userFullName: string;
  items: MenuItem[];
  message: Message[] = [];
  profileUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
  changePasswordPopup = false;
  editProfilePopup = false;
  subscription: Subscription;
  notifications: any = [];
  notificationCount: any;
  unReadNotificatonsCount = 0;
  unReadNotificatonsGreaterCount = '99+';
  limit = 5;
  offset = 0;
  showMoreOff = true;
  hasShowNotificationBar = false;
  notificationLabel;
  noNotificationFoundLabel;
  secondsCounter: any;
  logoutSession: any;
  headerConfirmDialog = false;
  headerMessage;

  constructor(
    private uiService: UiService,
    private userCommandService: UserCommonService,
    private router: Router,
    private commonBindingDataService: CommonBindingDataService,
    private storageService: StorageService,
    private messageService: MessageService,
    private notificationsService: NotificationsService) {
  }

  ngOnInit() {
    document.getElementsByTagName('body')[0].classList.toggle('opened');
    this.setSessionInterval();
    this.getUserMetaData();

    this.notificationLabel = this.commonBindingDataService.getLabel('label_notifications');
    this.noNotificationFoundLabel = this.commonBindingDataService.getLabel('label_no_notification_found');

    this.items = [
      {
        label: 'Edit Profile', icon: 'fa fa-user-circle-o', command: (event) => {
          this.editProfile();
        }
      },
      {
        label: 'Change Password', icon: 'fa fa-key', command: (event) => {
          this.changePassword();
        }
      },
      {
        label: 'Logout', icon: 'fa fa-sign-out', command: (event) => {
          this.doSignOut();
        }
      }
    ];

    const userObj: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (userObj !== undefined && userObj !== null) {
      this.userFullName = userObj.firstName + ' ' + userObj.lastName;
      if (userObj.userDetails !== undefined && userObj.userDetails !== null
        && userObj.userDetails.profileImage !== null && userObj.userDetails.profileImage !== '') {
        this.profileUrl = AppSettings.GET_FILE_THUMB_URL + userObj.userDetails.profileImage;
      } else {
        this.profileUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
      }
    }

    this.subscription = this.uiService.profileUpdatedEvent
      .subscribe((state: string) => {
        this.updateProfileContent();
      });

    this.notificationProcessInitialization();

  }

  getUserMetaData() {
    const interval = +this.storageService.getItemFromCookies(AppSettings.INTERVAL);
    if (interval && interval > 0) {
      this.userCommandService.getUserMasterMetaData();
    }
  }

  notificationProcessInitialization() {
    this.notificationsService.startFetchingNotifications(() => {
      this.getNotificationUnReadcountCount();
    });
  }

  getNotificationUnReadcountCount() {
    this.notificationsService.getCount().subscribe(data => {
      this.unReadNotificatonsCount = data.unReadcount;
    });
  }

  getNotificationListAndToggle(event) {
    this.unReadNotificatonsCount = 0;
    this.limit = 8;
    this.offset = 0;
    this.notifications = [];
    this.getNotificationList();
    this.hasShowNotificationBar = true;
  }

  getNotificationList() {
    this.notificationsService.getDetails(this.limit, this.offset).subscribe(notification => {
      if (notification.data.length > 0) {
        this.notificationCount = notification.count;
        this.offset = this.offset + notification.limit;
        this.notifications = [...this.notifications, ...notification.data];
      } else {
        this.showMessage('Error', this.noNotificationFoundLabel);
      }
    });
  }

  loadMoreNotification() {
    this.getNotificationList();
  }

  onSideMenuClick(event: any) {
    event.preventDefault();
    document.getElementsByTagName('body')[0].classList.toggle('closed');
    this.uiService.sidebarToggled();
  }

  doSignOut() {
    this.logout();
  }

  logout() {
    this.userCommandService.signOut().subscribe(results => {
      this.storageService.removeAllCookies();
      this.storageService.removeAll();
      this.notificationsService.stopFetchingNotifications();
      window.location.reload();
      this.router.navigate(['/signin']);
      this.notificationsService.canFetchNotification = false;
      this.headerConfirmDialog = false;
    }, (error) => {
      this.showMessage('Error', error.general[0].message);
    });
  }

  updateProfileContent() {
    this.userCommandService.getUserProfile().subscribe(results => {
      this.storageService.setItem(AppSettings.USER_DETAILS, results);
      AppSettings.USER = results;
      this.userFullName = results.firstName + ' ' + results.lastName;
      if (results.userDetails !== undefined && results.userDetails !== null && results.userDetails.profileImage !== null) {
        this.profileUrl = AppSettings.GET_FILE_THUMB_URL + results.userDetails.profileImage;
      }
    }, (error) => {
      this.showMessage('Error', error.general[0].message);
    });
  }

  changePassword() {
    this.changePasswordPopup = true;
  }

  editProfile() {
    this.editProfilePopup = true;
    this.editPro.setData();
  }

  onCancelEditProfile(event: any) {
    this.editProfilePopup = !this.editProfilePopup;
  }

  onCancelChangePassword(event: any) {
    this.changePasswordPopup = !this.changePasswordPopup;
  }

  showMessage(severity, message) {
    this.message = [];
    this.messageService.add({ severity: severity.toLowerCase(), summary: severity, detail: message });
  }

  setSessionInterval() {
    const sessionInterval = timer(10000, 1000);
    this.secondsCounter = sessionInterval.subscribe(n => {
      const idleSessionTime = +this.storageService.getItemFromCookies(AppSettings.INTERVAL);
      if (idleSessionTime) {
        if (idleSessionTime < new Date().getTime()) {
          this.confirmYouThere();
          this.secondsCounter.unsubscribe();
        } else {
          clearTimeout(this.logoutSession);
        }
      }
    });
  }

  confirmYouThere() {
    this.sessionTimeout();
    this.headerConfirmDialog = true;
    this.headerMessage = this.commonBindingDataService.getLabel('lbl_confirm_idle_session');
  }

  sessionTimeout() {
    this.logoutSession = setTimeout(() => {
      const idleSessionTime = +this.storageService.getItemFromCookies(AppSettings.INTERVAL);
      if (idleSessionTime < new Date().getTime()) {
        this.logout();
      } else {
        clearTimeout(this.logoutSession);
      }
    }, AppSettings.IDLE_TIME_ON_CONFIRM_MSG);
  }

  refreshSession() {
    const idleTime = '' + moment().add((AppSettings.IDLE_TIME), 'm').valueOf();
    this.storageService.setItemInCookies(AppSettings.INTERVAL, idleTime);
    clearTimeout(this.logoutSession);
    this.userCommandService.sesionRefresh().subscribe(() => { });
    this.setSessionInterval();
    this.headerConfirmDialog = false;
  }

  cancel() {
    this.headerConfirmDialog = false;
  }

  ngOnDestroy() {
    this.notificationsService.stopFetchingNotifications();
    this.subscription.unsubscribe();
    this.secondsCounter.unsubscribe();
    clearTimeout(this.logoutSession);
    this.storageService.removeAllCookies();
  }

}
