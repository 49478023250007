<div class="company-logo left-panel flex align-items-center justify-content-center">
  <img src="/assets/images/temp/logo-nemp.png">
</div>
<div class="right-panel">
  <div class="app-header col-1 md:col-1 lg:col-1">
    <div class="top-bar-item">
      <a (click)="onSideMenuClick($event)" class="hamburger-menu">
        <i class="fa fa-bars"></i>
      </a>
    </div>
  </div>
  <div class="col-11 md:col-11 lg:col-11 profile-panel">
    <div class="nav-right-menu">
      <div class="top-bar-item notifications-bell" (click)="getNotificationListAndToggle($event)">
        <a class="nav-notifications-icon">
          <i class="fa fa-bell-o" aria-hidden="true"></i>
          <div class="animated-new-notification-count" *ngIf="unReadNotificatonsCount">
            <span *ngIf="unReadNotificatonsCount < 100">{{unReadNotificatonsCount}}</span>
            <span *ngIf="unReadNotificatonsCount >= 100">{{unReadNotificatonsGreaterCount}}</span>
          </div>
        </a>
      </div>

      <div class="profile-menu-button top-bar-item">
        <p-menu #menu popup="popup" [model]="items"></p-menu>
        <a (click)="menu.toggle($event)">
          <img class="user-photo" src="{{profileUrl}}" onerror="src='/assets/images/temp/default_profile.png'">
          {{userFullName}}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="hasShowNotificationBar" [style]="{width: '400px'}" position="right">
  <div class="notification-container">
    <div class="header flex justify-content-between">
      <h3 class="header-heading">{{notificationLabel}}</h3>
      <span class="pi pi-times close-times-btn" (click)="hasShowNotificationBar = false"></span>
    </div>
    <div class="content">
      <app-notification-mg1 [notifications]="notifications" [notificationCount]="notificationCount"
        (loadMoreNotification)="loadMoreNotification()">
      </app-notification-mg1>
    </div>
  </div>
</p-sidebar>

<p-dialog [blockScroll]="true" modal="true" [showHeader]="false" styleClass="add-terms-popup" [style]="{width: '900px'}"
  [(visible)]="changePasswordPopup" [closable]="false">
  <app-change-password [(changePasswordPopup)]="changePasswordPopup"
    (isShowChangePassword)="onCancelChangePassword($event)"></app-change-password>
</p-dialog>

<p-dialog [blockScroll]="true" modal="false" [showHeader]="false" styleClass="edit-profile-popup"
  [style]="{width: '900px'}" [(visible)]="editProfilePopup">
  <app-edit-profile [(editProfilePopup)]="editProfilePopup" (showProfilePopup)="onCancelEditProfile($event)">
  </app-edit-profile>
</p-dialog>

<p-toast></p-toast>

<p-dialog [blockScroll]="true" modal="false" [showHeader]="false" styleClass="edit-profile-popup"
  [style]="{width: '500px'}" [(visible)]="headerConfirmDialog">
  <div class="grid form-container">
    <div class="col-12 header-dialog-heading">
      <p translate>label_confirmation</p>
    </div>
    <div class="col-12 header-dialog-content">
      {{headerMessage}}
    </div>
    <div class="col-12 form-buttons">
      <button class="negative-button" pButton type="button" icon="fa fa-close" label="No"
        (click)="refreshSession()"></button>
      <button pButton type="button" label="Yes" icon="fa fa-check" (click)="refreshSession()"></button>
    </div>
  </div>
</p-dialog>