import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { UiService } from '../../services/ui.service';
import { Grid2Service } from './grid2.service';
import { Grid2Model } from './grid-2-model';
import { Table } from 'primeng/table';
import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'app-mi-grid-v2',
  providers: [Grid2Service],
  templateUrl: './mi-grid-2.component.html'
})

export class MiGrid2Component implements OnInit, OnDestroy {
  subscription: Subscription;
  rowData: any[] = [];
  search = '';
  @Output() rowClickedEvent = new EventEmitter<any>();
  @Output() totalCount = new EventEmitter<any>();
  @Output() actionBtnClickedEvent = new EventEmitter<any>();
  @Output() getGridReference: EventEmitter<any> = new EventEmitter();
  @Output() validateExportEvent = new EventEmitter<any>();
  @Input() url: any = '';
  @Input() bottomSearchBar: Boolean;
  @Input() isDetailScreen: Boolean;
  @Input() bottomExportButton = false;
  @Input() rowSelection = 'single';
  @Input() printUrl: any = '';
  @Input() exportUrl: any = '';
  @Input() columnDefs: any[] = [];
  @Input() actionButtonsDefs: any[] = [];
  @Input() pagination: any = true;
  @Input() paginationPageSize: any = 15;
  @Input() paginationStartPage: any = 0;
  @Input() defaultSortField = '';
  @Input() divClass = '';
  @Input() isEmail = false;
  @Input() isValidation = false;
  @ViewChild('dt', { static: false }) dt: Table;

  queryParams: any = '';
  enableResizer = 'false';
  @Input() overlayLoadingTemplate: any = '<span class="ag-overlay-loading-center b-red">' +
    'Please wait while your rows are loading</span>';
  datasource = [];
  model: Grid2Model;
  totalRecords = 0;
  sortF = '';
  first: any = 0;
  sortO = '';
  offset = 0;
  setRowLength = 10;
  searchText = '';
  selectedRecords: any = [];
  constructor(private uiService: UiService, private gridService: Grid2Service) { }

  ngOnInit() {
    this.subscription = this.uiService.sidebarToggledEvent
      .subscribe((state: string) => {
        this.refreshList();
      });
  }

  onResize(event: any) {
    this.refreshList();
  }

  refreshList() {
    this.fetchAndLoadData();
  }

  fetchAndLoadData() {
    const that = this;
    this.gridService.loadGridData(that.getUrl(that.url)).subscribe(result => {
      const model = <Grid2Model>result;
      if (result !== undefined) {
        this.totalRecords = model.count;
        that.datasource = [];

        model.data.forEach(e => {
          that.columnDefs.forEach(c => {
            if (c && c.cellRenderer) {
              e[c.field] = c.cellRenderer(e[c.field]);
            } else if (c && c.cellRendererHtml) {
              e[c.field] = c.cellRendererHtml(e);
            }

            that.actionButtonsDefs.forEach(d => {
              if (d.show) {
                e[d.field] = d.show(e);
              }
            });
          });
          that.datasource.push(e);
        });
      }
      this.totalCount.emit(this.totalRecords);
      this.dt.first = this.offset;
    });
  }

  getUrl(url) {
    const that = this;
    let params = 'searchText=' + this.searchText + '&offset=' + this.offset + '&limit=' + this.setRowLength;
    params += '&sortColumn=';
    params += this.sortF;
    params += '&sortType=';
    params += this.sortO;
    let buildUrl = url;
    if (url.lastIndexOf('?') < 0) {
      buildUrl += '?';
    } else {
      buildUrl += '&';
    }
    buildUrl += params;
    return buildUrl;
  }

  public onQuickFilterChanged($event, dt) {
    if ($event.key === 'Enter') {
      this.applySearch(this.search);
      dt.first = 0;
    }
  }

  updateUrl(url: string) {
    this.url = url;
    this.offset = 0;
    this.first = 0;
    const paramArray: any[] = this.url.split('?');
    if (paramArray.length > 1) {
      this.queryParams = paramArray[1];
    }
    this.refreshList();
  }

  updateData(url: string) {
    this.url = url;
    const paramArray: any[] = this.url.split('?');
    if (paramArray.length > 1) {
      this.queryParams = paramArray[1];
    }
    this.refreshList();
  }

  loadDatasourceLazy(event: LazyLoadEvent) {
    this.sortF = this.defaultSortField;
    this.offset = event.first;
    this.first = event.first;
    this.setRowLength = event.rows;
    if (event.sortField !== undefined) {
      this.sortF = event.sortField;
    }
    this.sortO = event.sortOrder > 0 ? 'asc' : 'desc';
    this.fetchAndLoadData();
  }

  onRowSelect(event) {
    this.rowClickedEvent.emit(event);
  }

  onRowUnselect(event) {
  }

  actionBtnClick(data: any, action: string) {
    const obj = { row: data, class: action };
    this.actionBtnClickedEvent.emit(obj);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getSelectedRecords() {
    return this.selectedRecords;
  }

  applySearch(searchText: string) {
    this.searchText = searchText;
    this.offset = 0;
    this.first = 0;
    this.fetchAndLoadData();
  }

  exportGridExcel(event: any) {
    if (this.isValidation) {
      this.validateExportEvent.emit({ type: 'excel' });
    } else {
      this.exportExcel();
    }
  }

  exportExcel() {
    const that = this;
    this.gridService.downloadExcelGridData(that.getUrl(that.exportUrl + '?' + this.searchText + '&' + this.queryParams), that.isEmail);
  }

  exportGridPdf(event: any) {
    if (this.isValidation) {
      this.validateExportEvent.emit({ type: 'pdf' });
    } else {
      this.exportPdf();
    }
  }

  exportPdf() {
    const that = this;
    this.gridService.downloadPdfGridData(that.getUrl(that.printUrl + '?' + this.searchText + '&' + this.queryParams), that.isEmail);
  }

}
