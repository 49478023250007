<div class="signin-box-container grid">
  <div class="signin-box-inner-container">
    <div class="signin-box md:col-4 col-offset-4">
      <div class="forgot-pass-form">
        <div class="form-field col-12 p-0">
          <div class="form-field col-12 p-0 text-center">
            <img src='./assets/images/temp/logo-nemp.png'>
          </div>
          <div class="text-center col-12">
            <p class="buttom-text" translate>label_otp_send_text</p>
          </div>
          <div class="form-field col-12"></div>
        </div>
        <div class="form-field col-12 md:col-12 lg:col-12 mt-30">
          <form [formGroup]='otpForm'>
            <div class="form-field col-12 md:col-12 lg:col-12">
              <div class="form-label">
                <label for="username" translate>label_otp_header</label>
              </div>
              <div class="form-control">
                <input autocomplete="off"  formControlName="otpInput" class="bottom-border" type="text" pInputText />
              </div>
              <div class="form-errors"
                *ngIf="otpForm.controls.otpInput.errors  && (otpForm.controls.otpInput.dirty || otpForm.controls.otpInput.touched)">
                <div [hidden]="!otpForm.controls.otpInput.errors.required" translate>
                  invalid_otp
                </div>
              </div>
            </div>

            <div class="form-field col-12">
              <div class="form-buttons col-12 p-0">
                <button class='signin-save-btn' type="button" label="Continue" [disabled]="!otpForm.valid"
                  (click)="changePassword($event)" pButton></button>
                <button pButton class="cancel-back-btn " type="button" label="Cancel"
                  (click)="onBackSign($event)"></button>
              </div>
            </div>

          </form>
        </div>
        <div class="remove-float"></div>
      </div>

    </div>
  </div>
</div>

<p-toast></p-toast>