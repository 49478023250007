import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings } from 'app/modules/shared/app.settings';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';

@Component({
  selector: 'app-download-file',
  templateUrl: './download-file.component.html',
  styleUrls: ['./download-file.component.scss']
})
export class DownloadFileComponent implements OnInit {
  fileId = '';
  labelDownload = '';
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private commonBindingService: CommonBindingDataService
  ) {
    this.labelDownload = this.commonBindingService.getLabel('label_download');
    this.fileId = this.activatedRoute.snapshot.queryParams.fileid;
    if (this.fileId === '') {
      this.router.navigate(['signin']);
    }
  }

  ngOnInit() {
    this.downloadFile();
  }

  downloadFile() {
    const url = AppSettings.BASE_URL + '/' + AppSettings.TENANT + '/secure/file/' + this.fileId;
    window.open(url, '_blank');
  }
}
